import React from 'react';
import {useGameStore} from "../../store";
import {LottiePlayer} from "../../components";

export const BlockingOverlay = ({ children }: any) => {
  const isBlocked = useGameStore((state)=> state.isBlocked );
  return (
    <>
      {
        isBlocked ?
          <div className="flex h-dvh w-full bg-black text-white items-center justify-center absolute">
            <div className={"flex flex-col items-center justify-center w-10/12"}>
              <div className={"w-32"}>
                <LottiePlayer lottieUrl={'https://cdn.notwise.co/stickers/24.json'} isLoop={true} preloaderStyle={'rounded'}/>
              </div>
              <div className={"text-center"}>
                <h2 className={"text-2xl font-bold uppercase mt-5 mb-2"}>A bit late, browl</h2>
                NOT Wise open beta is now limited to 1000 participants.
              </div>
            </div>
          </div>
          :
          children
      }
    </>
  );
};
