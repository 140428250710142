import {Stage1} from "./Stage1";
import {Stage2} from "./Stage2";
import {Stage3} from "./Stage3";
import {useUserStore} from "../../store";
import {STAGES} from "../../config";

export const Stage = () => {

  const currentExp = useUserStore((state) => state.user.totalExp);
  return(
    <>
    {
      currentExp < STAGES[2] ?
        <Stage1/>
       :
        currentExp < STAGES[3] ?
          <Stage2/>
          :
          <Stage3/>
    }
  </>
  )
}
