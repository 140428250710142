import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Round } from '../../utils/math';
import { tg_account, tg_haptic } from '../../utils/telegramapi';
import { LEVELS, REACT_APP_BACKEND_URL } from '../../config';
import Add from '../../assets/icon-add.svg';
import { SKILL_DESCR } from '../../constants';
import { useUserStore } from '../../store';
import { Inventory, MainNav, Progressbar } from '../../components';
import { IUserinfo } from '../../types/interface';
import Starfield from '../../components/Starfield';
import ACH from './achievements.json';
import AchievementsGrid from '../../components/AchievementsGrid';
import toast from 'react-hot-toast';
import { useTokenStore } from '../../store/useTokenStore';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

type ProfileTabs = 'skills' | 'inventory' | 'stickers';

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TabButton = styled.button<{ active: boolean }>`
  padding: 12px 16px;
  background: ${(props) => (props.active ? '#0F152A' : 'transparent')};
  color: #fff;
  cursor: pointer;
  border-radius: 12px 12px 0 0;
  text-align: center;
  border: ${(props) => (props.active ? '1px solid #202232' : 'none')};
  position: relative;
  margin-bottom: -1px;
  border-bottom: none;
  &:focus {
    outline: none;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  width: 100%;
  height: 100%;
`;

const SKILLS: Array<{
  title: string;
  skill: keyof IUserinfo;
}> = [
  { title: 'Strength', skill: 'strength' },
  { title: 'Agility', skill: 'agility' },
  { title: 'Wisdom', skill: 'wisdom' },
  { title: 'Endurance', skill: 'endurance' },
];

export const Profile = () => {
  const userInfo = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const [activeTab, setActiveTab] = useState<ProfileTabs>('inventory');
  const [username, setUsername] = useState<string | undefined>(
    userInfo.username ? userInfo.username : tg_account?.username,
  );

  const token = useTokenStore((state) => state.accessToken);

  const [isAddLoading, setIsAddLoading] = useState(true);

  const progress =
    ((LEVELS[userInfo.level] - userInfo.totalExp) / (LEVELS[userInfo.level + 1] - LEVELS[userInfo.level])) * -100;
  useTelegramBackButton('/');

  const upSkill = async (skill: keyof IUserinfo) => {
    setUser({
      ...userInfo,
      skillPoints: userInfo.skillPoints - 1,
      [skill]: userInfo[skill] + 1,
    });
    try {
      await axios({
        method: 'post',
        url: `${REACT_APP_BACKEND_URL}/up-skills/`,
        headers: { Authorization: `Bearer ${token}` },
        params: { skill },
      });
      toast.success(`${skill} increased by 1`, {
        icon: '️🤟',
        style: {
          borderRadius: '30px',
          background: '#090e1de3',
          color: '#fff',
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="block w-full h-full overflow-x-hidden top-0 absolute">
      <Starfield amount={30} />
      <Wrapper className="bg-[#080D1B] overflow-y-auto scrollable no-scrollbar">
        <div className="w-full">
          <div className="webapp-safe-top flex flex-col bg-gradient-to-tr from-transparent via-[#48518530] backdrop-blur to-transparent border border-[#1D2338] rounded-3xl w-full mb-1 items-center justify-center py-6 px-5">
            <div className="flex relative w-full justify-between">
              <div className="relative shadow-[0_2px_28px_0_#661A79BA] flex p-0.5 bg-gradient-to-b from-[#FF9A46] to-[#F4C9A5] rounded-full justify-center items-center">
                <img
                  src={userInfo?.avatar ? userInfo.avatar : '/assets/images/avatar.png'}
                  className="rounded-full block w-12 h-12"
                  alt={'avatar'}
                />
                <span className="absolute -bottom-1 left-0">
                  <svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.3046 2.76465C13.921 0.816768 17.5056 0.816768 20.122 2.76464L20.5112 3.05441C21.2682 3.61799 22.1262 4.0312 23.0388 4.27166L23.508 4.3953C26.6622 5.2264 28.8972 8.02895 29.0056 11.289L29.0217 11.774C29.0531 12.7172 29.265 13.6457 29.646 14.5091L29.8419 14.9531C31.1587 17.9373 30.3611 21.432 27.8798 23.5494L27.5107 23.8644C26.7928 24.477 26.199 25.2216 25.7615 26.0578L25.5366 26.4877C24.0244 29.3779 20.7948 30.9332 17.5924 30.3134L17.116 30.2212C16.1894 30.0419 15.2371 30.0419 14.3105 30.2212L13.8341 30.3134C10.6317 30.9332 7.40206 29.3779 5.88992 26.4877L5.66497 26.0578C5.22747 25.2216 4.63369 24.477 3.91579 23.8644L3.54668 23.5494C1.06543 21.432 0.267787 17.9373 1.58462 14.9531L1.78052 14.5091C2.16152 13.6457 2.37344 12.7172 2.40479 11.774L2.42092 11.289C2.5293 8.02895 4.76426 5.2264 7.91847 4.3953L8.3877 4.27166C9.3003 4.0312 10.1583 3.61799 10.9153 3.05442L11.3046 2.76465Z"
                      fill="url(#paint0_linear_185_4292)"
                      stroke="white"
                      strokeWidth="0.824721"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_185_4292"
                        x1="5.5183"
                        y1="4.40184"
                        x2="23.9278"
                        y2="33.0183"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF7B43" />
                        <stop offset="1" stopColor="#FFE04E" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className={`absolute text-white font-bold top-0.5 w-6 text-sm text-center`}>
                    {userInfo.level}
                  </span>
                </span>
              </div>
              <div className="flex flex-col justify-center items-start ml-3 flex-grow">
                <div className="flex items-center gap-2">
                  {username ? (
                    <h6 className={`${username && username.length > 7 ? 'text-xs' : 'text-lg'} font-medium text-white`}>
                      {username}
                    </h6>
                  ) : (
                    <h6 className={`text-lg font-medium text-white`}>Anonymous</h6>
                  )}
                </div>
                <p className="text-xs font-normal text-white/[.4]">{userInfo.squad?.name}</p>
              </div>
              <div className="text-lg flex justify-center items-center">
                <img alt="img" src="https://notwise.b-cdn.net/icons/coin-icon.png" width={18} height={18} />
                <p className="font-bold text-white ml-2">{userInfo.coins}</p>
              </div>
            </div>
            <div className="flex w-full mt-4">
              <Progressbar
                blurred
                winking={false}
                barcolor="bg-gradient-to-r from-[#FF6F41] to-[#FEFBE9]"
                ht="min-h-3"
                percent="w"
                spark={Round(progress, 1) > 20}
                value={userInfo.totalExp}
                maxvalue={LEVELS[userInfo.level + 1]}
                customStyle={{ width: `${Round(progress, 1)}%` }}
              />
            </div>
            <div className="flex w-full items-center mt-4 justify-between">
              <div className="text-sm font-normal text-white w-8/12">
                {userInfo.totalExp}&nbsp;&nbsp;/&nbsp;&nbsp;
                <span className="text-white/[.4]">{LEVELS[userInfo.level + 1]} EXP</span>
              </div>
              <div className="flex text-white items-center">
                <span className="nw-icon-wingl text-2xl text-white/[.6]"></span>
                <span className="mx-2 font-medium opacity-90">#{userInfo.rank}</span>
                <span className="nw-icon-wingr text-2xl text-white/[.6]"></span>
              </div>
            </div>
          </div>

          <div
            className={`w-12/12 flex items-center justify-between my-4 m-auto py-4 ${
              userInfo.totalExp < LEVELS[1] ? 'hidden' : 'block'
            }`}
          >
            <div className="w-3/12 flex items-center">
              <div className="flex flex-col  ml-3">
                <div className="text-white text-sm flex items-center">
                  <span className="text-lg mr-1">🐛</span> Hunger
                </div>
                <div className="text-white ml-6">
                  {userInfo.hunger} <span className="text-white/[60%]">/ 20</span>
                </div>
              </div>
            </div>
            <div className="w-3/12 flex items-center">
              <div className="flex flex-col">
                <div className="text-white text-sm flex items-center">
                  <span className="text-lg mr-1">🥺</span> Happiness
                </div>
                <div className="text-white ml-6">
                  {userInfo.happiness} <span className="text-white/[60%]">/ 20</span>
                </div>
              </div>
            </div>
            <div className="w-3/12 flex items-center">
              <div className="flex flex-col">
                <div className="text-white text-sm flex items-center">
                  <span className="text-lg mr-1">❤️‍🩹</span> Health
                </div>
                <div className="text-white ml-6">
                  {userInfo.health} <span className="text-white/[60%]">/ {20 + userInfo.endurance * 3}</span>
                </div>
              </div>
            </div>
          </div>
          <Tabs className={'mt-3'}>
            <TabButton
              className={`relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm`}
              active={activeTab === 'inventory'}
              onClick={() => setActiveTab('inventory')}
            >
              Inventory
            </TabButton>
            <TabButton
              className={`relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm`}
              active={activeTab === 'skills'}
              onClick={() => setActiveTab('skills')}
            >
              <div>Skills</div>
              {userInfo.skillPoints > 0 && (
                <span className="absolute flex h-2 w-2 right-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75" />
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-red-500" />
                </span>
              )}
            </TabButton>
            <TabButton
              className={`relative pointer-events-auto flex justify-center items-center flex-grow font-medium text-sm`}
              active={activeTab === 'stickers'}
              onClick={() => setActiveTab('stickers')}
            >
              <span className="nw-icon-telegram text-2xl text-white mr-1"></span> Stickers
            </TabButton>
          </Tabs>
          {activeTab === 'skills' && (
            <div className="w-full text-center gap-3 bg-[#0F152A] border border-[#202232] rounded-xl p-4">
              <div className="flex justify-between">
                <div className="flex flex-col gap-4">
                  {SKILLS.slice(0, 2).map(({ skill, title }, index) => (
                    <div className="flex gap-1" key={index}>
                      <div className="flex items-center justify-center text-lg font-bold text-white mb-0 pb-0">
                        {userInfo.skillPoints > 0 ? (
                          <button
                            className="pointer-events-auto relative"
                            onClick={() => {
                              tg_haptic.impactOccurred('light');
                              upSkill(skill);
                            }}
                          >
                            <img className="w-7 h-7" src={Add} alt="Add" onLoad={() => setIsAddLoading(false)} />
                            {isAddLoading && (
                              <div className="absolute top-0 left-0 w-7 h-7 rounded-full bg-slate-700 animate-pulse" />
                            )}
                          </button>
                        ) : (
                          <img
                            className="w-5 h-5 rounded-full"
                            src={`https://notwise.b-cdn.net/icons/${title.toLowerCase()}.png`}
                            alt="icon"
                          />
                        )}
                        <span className="min-w-7">{userInfo[skill]}</span>
                      </div>
                      <div className="flex flex-col justify-start text-left">
                        <div className="items-center text-sm font-bold text-white">{title}</div>
                        <div className="items-center text-xs font-normal text-white/[60%]">{SKILL_DESCR[title]}</div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col gap-4">
                  {SKILLS.slice(2, 4).map(({ skill, title }, index) => (
                    <div className="flex gap-1" key={index}>
                      <div className="flex items-center justify-center text-lg font-bold text-white mb-0 pb-0">
                        {userInfo.skillPoints > 0 && title.toLowerCase() !== 'wisdom' ? (
                          <button
                            className="pointer-events-auto relative"
                            onClick={() => {
                              tg_haptic.impactOccurred('light');
                              upSkill(skill);
                            }}
                          >
                            <img className="w-7 h-7" src={Add} alt="Add" onLoad={() => setIsAddLoading(false)} />
                            {isAddLoading && (
                              <div className="absolute top-0 left-0 w-7 h-7 rounded-full bg-slate-700 animate-pulse" />
                            )}
                          </button>
                        ) : (
                          <img
                            className="w-5 h-5 rounded-full"
                            src={`https://notwise.b-cdn.net/icons/${title.toLowerCase()}.png`}
                            alt="icon"
                          />
                        )}
                        <span className="min-w-7">{userInfo[skill]}</span>
                      </div>
                      <div className="flex flex-col justify-start text-left">
                        <div className="items-center text-sm font-bold text-white">{title}</div>
                        <div className="items-center text-xs font-normal text-white/[60%]">{SKILL_DESCR[title]}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {activeTab === 'inventory' && (
            <div
              className={`w-full bg-[#0F152A] border border-[#202232] rounded-xl rounded-tl-none p-4 pointer-events-auto mb-24`}
            >
              <Inventory />
            </div>
          )}
          {activeTab === 'stickers' && (
            <div
              className={`w-full bg-[#0F152A] border border-[#202232] rounded-xl rounded-tr-none p-4 text-white pointer-events-auto mb-24`}
            >
              {/*<MainButton isLoading={false} onClick={() => alert('clicked')} btnText={'Update telegram stickers'} />*/}
              <AchievementsGrid achievements={ACH} userAchievements={userInfo.achievements} />
            </div>
          )}
        </div>
      </Wrapper>
      <MainNav
        skillPoints={userInfo.skillPoints}
        userExp={userInfo.totalExp}
        isBranch={false}
        gameMode="normal"
        setIsNeedShown={false}
        setGameMode={null}
      />
    </div>
  );
};
