import * as THREE from "three";
import { useEffect } from "react";

const clock = new THREE.Clock();

let timestamp = Date.now();
const dateTime = new Date();
dateTime.setTime(timestamp);

let interval: NodeJS.Timeout;

export const useDayNightCycle = (listener: ({
  time,
  daynightCycle
}: {
  time: number;
  daynightCycle: number;
}) => void) => {
  useEffect(() => {
    const evaluateDayNightCycle = () => {
      const time = clock.getElapsedTime();
      dateTime.setTime(timestamp + time * 1000.0);

      const daynightCycle = (Math.abs(dateTime.getUTCHours() - 12) / 12 - 0.5) * 2.0;
      listener({ time, daynightCycle });
    };

    fetch('https://api.masonsplay.com/time/', { method: 'GET' })
    .then(response => {
      if (response.status !== 200) {
        throw new Error();
      }

      return response.json();
    })
    .then(data => {
      timestamp = data;
      dateTime.setTime(timestamp);
    })
    .catch(() => {
      timestamp = Date.now();
      dateTime.setTime(timestamp);
    })
    .finally(() => {
      if (interval) {
        clearInterval(interval);
      }

      interval = setInterval(evaluateDayNightCycle, 60 * 1000);
      evaluateDayNightCycle();
    });

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);
};
