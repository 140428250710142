import React from 'react';
import { useWebSocketContext } from './';
import { ReadyState } from 'react-use-websocket';

const WebsocketIndicator: React.FC = () => {
  const { readyState } = useWebSocketContext();

  const getStatusLabel = (): string => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        return 'Connecting...';
      case ReadyState.OPEN:
        return 'Connected';
      case ReadyState.CLOSING:
        return 'Closing...';
      case ReadyState.CLOSED:
        return 'Disconnected';
      default:
        return 'Unknown';
    }
  };

  const getStatusColor = (): string => {
    switch (readyState) {
      case ReadyState.CONNECTING:
        return '#0095ff';
      case ReadyState.OPEN:
        return '#5ee32c';
      case ReadyState.CLOSING:
        return '#ffae00';
      case ReadyState.CLOSED:
        return '#ff003b';
      default:
        return 'gray';
    }
  };

  return (
    <div className="flex gap-1 bottom-[50%] absolute items-center right-0 -rotate-90">
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: getStatusColor(),
          opacity: .5
        }}
      />
      <span className="text-[10px] text-white/[.3]">{getStatusLabel()}</span>
    </div>
  );
};

export default WebsocketIndicator;
