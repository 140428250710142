import * as THREE from 'three';
import { useEffect, useRef } from "react";
import { AudioSystemUtil } from "../particles/AudioSystemUtil";
import { useLocation } from "react-router-dom";
import { useDayNightCycle } from "../hooks/useDayNightCycle";

const skyColorDay = new THREE.Color(0xfff8f2);
const skyColorNight = new THREE.Color(0x3333ff);
const groundColorDay = new THREE.Color(0xffe2d2);
const groundColorNight = new THREE.Color(0xffffff);

export const Environment = ({
  sceneView
}: {
  sceneView: string
}) => {
  const hemisphereLightRef = useRef<THREE.HemisphereLight>(null!);
  const directionalLightRef = useRef<THREE.DirectionalLight>(null!);
  const route = useLocation();

  useEffect(() => {
    (async () => {
      await AudioSystemUtil.stopAll();

      if (sceneView === 'TreeView') {
        AudioSystemUtil.play('ambient-theme', true);

        if (route.pathname.startsWith('/stage')) {
          AudioSystemUtil.play('ambient-birds-chirp', true);
        }
      } else if (sceneView === 'TempleView') {
        AudioSystemUtil.play('ambient-battle', true);
      }
    })();
  }, [sceneView]);

  useEffect(() => {
    if (!hemisphereLightRef.current || !directionalLightRef.current) {
      return;
    }

    if (sceneView !== 'TreeView') {
      hemisphereLightRef.current.color.lerpColors(skyColorDay, skyColorNight, 0.0);
      hemisphereLightRef.current.groundColor.lerpColors(groundColorDay, groundColorNight, 0.0);
  
      directionalLightRef.current.intensity = 2.0;
    }
  }, [sceneView])

  useDayNightCycle(({ daynightCycle }) => {
    if (!hemisphereLightRef.current || !directionalLightRef.current) {
      return;
    }

    if (sceneView !== 'TreeView') {
      hemisphereLightRef.current.color.lerpColors(skyColorDay, skyColorNight, 0.0);
      hemisphereLightRef.current.groundColor.lerpColors(groundColorDay, groundColorNight, 0.0);
  
      directionalLightRef.current.intensity = 2.0;
    } else {
      hemisphereLightRef.current.color.lerpColors(skyColorDay, skyColorNight, (daynightCycle + 1.0) / 2.0);
      hemisphereLightRef.current.groundColor.lerpColors(groundColorDay, groundColorNight, (daynightCycle + 1.0) / 2.0);
  
      directionalLightRef.current.intensity = THREE.MathUtils.lerp(2.0, 1.0, daynightCycle);
    }
  });

  return (
    <>
      <hemisphereLight ref={hemisphereLightRef} />
      <directionalLight
        ref={directionalLightRef}
        castShadow={false}
        color="#ffe2d2"
        position={[10, 20, 10]}
        intensity={2.0}
      />
    </>
  )
};
