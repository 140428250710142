import { useEffect, useMemo, useState } from "react";
import { useCameraAnimation } from "../hooks/useCameraAnimation";
import { useGameStore } from "../../../store";
import { Environment } from "./Environment";
import { Skybox } from "./Skybox";
import { MidPlane } from "./MidPlane";
import { TemplePlane } from "./TemplePlane";
import { TreeModel } from "./TreeModel";
import { EggModel } from "./EggModel";
import { OwlModel } from "./OwlModel";
import { DummyModel } from "./DummyModel";
import { EntitySocket } from "./EntitySocket";
import { SceneView } from "../views";
import { STAGES } from "../../../config";

export const Scene = () => {
  const { gameMode, gameStage, foreignOwl } = useGameStore();

  // NOTE Using an intermediate states to avoid automatic model disposals before the scene
  //      transitions are completed
  const [ sceneView, setSceneView ] = useState<SceneView>('TreeView');
  const [ gameModeView, setGameModeView ] = useState<string>('normal');

  useCameraAnimation({ setSceneView });

  useEffect(() => {
    if (gameModeView === gameMode) {
      return;
    }

    setGameModeView(gameMode);
  }, [sceneView, gameMode !== 'battle']);

  const foreignOwlStage = useMemo(() => {
    if (!foreignOwl) {
      return null;
    }

    if (foreignOwl.experience >= STAGES[3]) {
      return 3;
    } else if (foreignOwl.experience >= STAGES[2]) {
      return 2;
    }

    return 1;
  }, [foreignOwl]);

  return (
    <>
      <Environment sceneView={sceneView} />

      {sceneView === 'TreeView' && (
        <>
          <Skybox position={[0, 10, -15]} />
          <EntitySocket
            position={[0, 4, 0]}
          >
            <TreeModel />
          </EntitySocket>
          {(foreignOwlStage ?? gameStage) === 1 && (
            <EntitySocket
              position={[ 0.3, 4.1, 0.0 ]}
            >
              <EggModel />
            </EntitySocket>
          )}
          {(foreignOwlStage ?? gameStage) >= 2 && (
            <EntitySocket
              position={[ 0.0, 2.9, 2.0 ]}
              rotation={[ 0.0, 0.0, 0.0 ]}
              scale={0.6}
            >
              <OwlModel mode="default" isForeignOwl={!!foreignOwl} />
            </EntitySocket>
          )}
        </>
      )}

      <MidPlane position={[0, -100, -16]} />

      {sceneView === 'TempleView' && (
        <>
          <TemplePlane position={[0, -200, -15]} />

          {/* NOTE Show training dummy */}
          {gameModeView === 'training' && (
            <EntitySocket position={[0.2, -215, -10]}>
              <DummyModel />
            </EntitySocket>
          )}
          {/* NOTE Show fight dummy */}
          {gameModeView === 'fighting' && (
            <EntitySocket position={[0.2, -215, -10]}>
              <DummyModel />
            </EntitySocket>
          )}

          {/* NOTE Show battling owls */}
          {gameModeView === 'battle' && (
            <>
              <EntitySocket
                position={[ -2, -210.0, 3.0 ]}
                rotation={[ 0.0, 2.1, 0.0 ]}
                scale={0.7}
              >
                <OwlModel mode="battle" />
              </EntitySocket>
              <EntitySocket
                position={[ 3.4, -210.0, -1 ]}
                rotation={[ 0.0, -1, 0.0 ]}
                scale={0.7}
              >
                <OwlModel mode="battle" isForeignOwl />
              </EntitySocket>
            </>
          )}
        </>
      )}
    </>
  );
};
