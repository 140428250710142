export const vertexShader = `
uniform float uTime;
varying vec2 vUv;
varying vec3 vWorldPosition;
varying vec3 vNormal;

void main() {
  vUv = uv;
  
  vec3 pos = position;
  //pos.y += sin(pos.x * 2.0 + uTime) * 0.1;
  
  vec4 modelPosition = modelMatrix * vec4(pos, 1.0);
  vWorldPosition = modelPosition.xyz;
  vNormal = normalMatrix * normal;

  vec4 viewPosition = viewMatrix * modelPosition;
  vec4 projectedPosition = projectionMatrix * viewPosition;

  gl_Position = projectedPosition;
}   
`;
