import { useEffect } from 'react';
import { tg_haptic } from '../utils/telegramapi';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

export const useTelegramBackButton = (navigationRoute?: string) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const BackButton = window.Telegram.WebApp.BackButton;

    if (BackButton && BackButton.isVisible && location.pathname === '/') {
      BackButton.hide();
      return;
    }

    if (BackButton && !BackButton.isVisible) {
      BackButton.show();
    }

    const callback = () => {
      if (navigationRoute) {
        navigate(navigationRoute);
      }
      tg_haptic.impactOccurred('light');
    };

    BackButton?.onClick(callback);

    return () => {
      BackButton.offClick(callback);
    };
  }, [location.pathname, navigate, navigationRoute]);
};
