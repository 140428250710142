import { create } from 'zustand';
import { UserInfo } from '../types/interface';
import toast from 'react-hot-toast';
import {LEVELS, MAX_ENERGY_BY_LEVEL} from '../config';
import { AudioSystemUtil } from '../components/GameSceneNew/particles/AudioSystemUtil';

export const useUserStore = create<UserInfo>((set, get) => ({
  user: {
    cola_used: false,
    avatar: null,
    achievements: [],
    totalExp: 0,
    temperature: null,
    energy: null,
    skillPoints: 0,
    level: 0,
    coins: 0,
    strength: 0,
    agility: 0,
    wisdom: 0,
    endurance: 0,
    hunger: 0,
    tg_id: 0,
    username: null,
    isPremium: false,
    lang: null,
    inventories: null,
    rank: 0,
    health: 0,
    happiness: 0,
    lastFeeding: null,
    lastHealing: null,
    lastSwiping: null,
    fetchedLastHeating: null,
    fetchedLastEnergyUsed: null,
    squad: null,
    last_heating: null,
    last_energy_used: null,
    current_battle: null,
    fetchedLastFeeding: null,
    fetchedLastSwiping: null,
    fetchedLastHealing: null,
  },
  isJoiningSquad: false,
  intermediate: {
    levelUps: 0,
  },
  foreigns: null,
  setUser: (payload) =>
    set((state) => ({
      ...state,
      user: {
        ...payload,
      },
    })),
  setIsJoiningSquad: (isJoiningSquad: boolean) => set((state) => ({ ...state, isJoiningSquad })),
  setAchievements: (achievements: Array<unknown>) =>
    set((state) => ({ ...state, user: { ...state.user, achievements } })),
  addExperienceAndReduceEnergy: (experienceToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      const updatedExperience = state.user.totalExp + experienceToAdd;
      const updatedEnergy = state.user.energy - energyToReduce;

      let updatedLevel = state.user.level;
      let deltaLevels = 0;

      if (updatedExperience >= LEVELS[updatedLevel + 1]) {
        while (updatedExperience >= LEVELS[updatedLevel + 1]) {
          updatedLevel += 1;
          deltaLevels += 1;
        }
      }

      if (deltaLevels > 0) {
        toast.success(`Level UP, bro. LVL ${updatedLevel}`, {
          icon: '👏',
          style: {
            borderRadius: '30px',
            background: '#090e1de3',
            color: '#fff',
          },
        });

        AudioSystemUtil.play('sfx-level-up', false);
      }

      return {
        ...state,
        user: {
          ...state.user,
          totalExp: updatedExperience,
          energy: updatedEnergy,
          level: updatedLevel,
        },
        intermediate: {
          ...state.intermediate,
          levelUps: deltaLevels,
        },
      };
    }),
  addHappinessAndReduceEnergy: (happinessToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          happiness: state.user.happiness + happinessToAdd,
          energy: state.user.energy - energyToReduce,
        },
      };
    }),
  addTemperatureAndReduceEnergy: (temperatureToAdd: number, energyToReduce: number) =>
    set((state) => {
      if (state.user.temperature === null || state.user.energy === null) {
        return state;
      }

      const newEnergy = state.user.energy - energyToReduce;

      return {
        ...state,
        user: {
          ...state.user,
          temperature: state.user.temperature + temperatureToAdd,
          energy: newEnergy > 0 ? newEnergy : 0,
        },
      };
    }),
  decreaseTemperature: (temperature: number) =>
    set((state) => {
      if (state.user.temperature === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          temperature: Math.max(0, state.user.temperature - temperature),
        },
      };
    }),
  decreaseHappiness: (happiness: number) =>
    set((state) => {
      if (state.user.happiness === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          happiness: Math.max(0, state.user.happiness - happiness),
        },
      };
    }),
  increaseHealth: (health: number) =>
    set((state) => {
      if (state.user.health === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          health: Math.max(0, state.user.health + health),
        },
      };
    }),
  decreaseHunger: (hunger: number) =>
    set((state) => {
      if (state.user.hunger === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          hunger: Math.max(0, state.user.hunger - hunger),
        },
      };
    }),
  addEnergy: (energy: number) =>
    set((state) => {
      if (state.user.energy === null) {
        return state;
      }

      return {
        ...state,
        user: {
          ...state.user,
          energy: Math.min(MAX_ENERGY_BY_LEVEL[state.user.level], state.user.energy + energy),
        },
      };
    }),
  updateByUserInteraction: (last_energy_used: number, last_heating: number, temperature: number, action: string) =>
    set((state) => {
      if (action === 'heat') {
        return {
          user: {
            ...state.user,
            last_energy_used,
            last_heating: last_heating === 0 ? state.user.last_heating : last_heating,
            temperature: temperature === 0 ? state.user.temperature : temperature,
          },
        };
      }
      if (action === 'swipeOwl') {
        return {
          user: {
            ...state.user,
            happiness: state.user.happiness + 1,
          },
        };
      }
      if (action === 'feedOwl') {
        return {
          user: {
            ...state.user,
            hunger: state.user.hunger + 1,
          },
        };
      }

      return {
        user: {
          ...state.user,
          last_energy_used,
          last_heating: last_heating === 0 ? state.user.last_heating : last_heating,
          temperature: temperature === 0 ? state.user.temperature : temperature,
        },
      };
    }),
}));
