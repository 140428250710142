import { useMutation } from './useMutation';

type Input = {
  itemId: number;
};

export const useUseItem = ({ itemId }: Input) => {
  const { mutate, ...rest } = useMutation({
    url: `inventory/useitem/${itemId}`,
    method: 'post',
  });

  return { applyItem: mutate, ...rest };
};
