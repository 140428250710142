import { useEnergy } from './useEnergy';
import { useTemperature } from './useTemperature';
import { useHappiness } from './useHappiness';
import { useHunger } from './useHunger';
import { useHealth } from './useHealth';

export const useCharacterParams = () => {
  useEnergy();
  useTemperature();
  useHappiness();
  useHunger();
  useHealth();
};
