import coinIcon from '../../assets/icon-coin.svg';
import { useState } from 'react';
import {Link} from "react-router-dom";

type Props = {
  avatar?: string;
  name?: string;
  frenCount?: number;
  coins?: number;
  isLoading?: boolean;
  tg_id?: number;
};

export const Fren = ({ avatar, name, frenCount, coins, tg_id, isLoading }: Props) => {
  const frenStr = `+ ${frenCount} fren${(frenCount ?? 1) % 10 === 1 ? '' : 's'}`;

  const [isImageLoading, setIsImageLoading] = useState(true);
  const [isCoinIconLoading, setIsCoinIconLoading] = useState(true);

  return (
    <div className="grid grid-cols-2 w-full">
      <div className="flex space-x-2.5">
        {avatar && (
          <img src={avatar} alt="avatar" className="w-9 h-9 rounded-[50%]" onLoad={() => setIsImageLoading(false)} />
        )}
        {(isLoading || isImageLoading) && <div className="w-9 h-9 rounded-full bg-slate-700 animate-pulse" />}

        <div className="flex flex-col justify-between">
          {!isLoading && <Link to={`/freninfo/${tg_id}`}><span className="text-white font-bold text-sm">{name ? name : 'id'+tg_id} <span className={"inline-flex text-white/[.2] nw-icon-arrow -rotate-90"}></span></span></Link>}
          {isLoading && <div className="h-3 w-14 bg-slate-700 animate-pulse" />}

          {!isLoading && <div className="text-[#E3EAF699] text-xs">{frenStr}</div>}
          {isLoading && <div className="h-3 w-14 bg-slate-700 animate-pulse" />}
        </div>
      </div>

      <div className="flex space-x-2 items-center justify-self-end">
        {!isLoading && (
          <img
            src={coinIcon}
            alt="coin"
            className="w-4 h-auto rounded-[50%]"
            onLoad={() => setIsCoinIconLoading(false)}
          />
        )}
        {(isCoinIconLoading || isLoading) && <div className="h-4 w-4 rounded-full bg-slate-700 animate-pulse" />}

        {!isLoading && <div className="text-white font-bold">{coins}</div>}
        {isLoading && <div className="h-3 w-5 bg-slate-700 animate-pulse" />}
      </div>
    </div>
  );
};
