import { useCallback, useEffect, useMemo, useRef } from 'react';
import { debounce } from 'debounce';
import { Action, ActionType, Mode, performAction } from '../api/performAction';
import { getExperienceBasedOnTemperature, showExperienceAnimation } from '../utils';
import { useUserStore } from '../store';
import { ParticleSystemUtil } from '../components/GameSceneNew/particles/ParticleSystemUtil';
import { Object3D, Object3DEventMap } from 'three';
import { BatchedRenderer } from 'three.quarks';
import { IUserinfo } from '../types/interface';
import { useTokenStore } from '../store/useTokenStore';

export type Coords = { x: number; y: number };
export const ENERGY_TO_REDUCE_ON_HOLD = 5;

const DEBOUNCE_INTERVAL = 300;

const getKFactor = (userInfo: IUserinfo): number => {
  if (userInfo.happiness === 100 && userInfo.hunger === 100 && userInfo.health === 100) {
    return 3;
  } else if (userInfo.happiness > 0 && userInfo.hunger > 0 && userInfo.health > 0) {
    return 2;
  } else {
    return 1;
  }
};

export const useUserAction = (mode: Mode) => {
  const user = useUserStore((state) => state.user);
  const { energy, temperature } = user;
  const temperatureRef = useRef(temperature);

  const token = useTokenStore((state) => state.accessToken);

  const addTemperatureAndReduceEnergy = useUserStore((state) => state.addTemperatureAndReduceEnergy);
  const addExperienceAndReduceEnergy = useUserStore((state) => state.addExperienceAndReduceEnergy);
  const addHappinessAndReduceEnergy = useUserStore((state) => state.addHappinessAndReduceEnergy);

  const sentStartDate = useRef<number | null>(null);

  const holdIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const holdSessionStart = useRef<Date | null>(null);
  const swipeSessionStart = useRef<Date | null>(null);

  const clicks = useRef(0);
  const holds = useRef(0);
  const swipes = useRef(0);
  const clickSessions = useRef<Array<Action>>([]);
  const currentClickSession = useRef<Action | null>(null);
  const clickSessionStart = useRef<Date | null>(null);

  const startClickSession = () => {
    clickSessionStart.current = new Date();
  };

  const clearClickSessionStart = () => {
    clickSessionStart.current = null;
  };

  const clearSwipeSessionStart = () => {
    swipeSessionStart.current = null;
  };
  const clearHoldSessionStart = () => {
    holdSessionStart.current = null;
  };

  const clearClickSessions = () => {
    clickSessions.current = [];
  };

  const clearCurrentClickSession = () => {
    currentClickSession.current = null;
  };

  const clearClicks = () => {
    clicks.current = 0;
  };

  const clearHolds = () => {
    holds.current = 0;
  };

  const clearSwipes = () => {
    swipes.current = 0;
  };

  const sendActionsToServer = useCallback(
    (payload: { type: ActionType; callback?: () => void; actions: Array<Action> }) => {
      token && performAction({ token, mode, ...payload });
    },
    [token, mode],
  );

  const debouncedSendActionsToServer = useMemo(
    () => debounce(sendActionsToServer, DEBOUNCE_INTERVAL),
    [sendActionsToServer],
  );

  const handleAddExperience = useCallback(
    (addedExpAmount: number, energyCost: number, coords?: Coords) => {
      if (energy === null || energy < energyCost || temperature === null) {
        return;
      }

      if (coords && addedExpAmount > 0) {
        showExperienceAnimation(coords, addedExpAmount);
      }

      addExperienceAndReduceEnergy(addedExpAmount, energyCost);
    },
    [addExperienceAndReduceEnergy, energy, temperature],
  );

  const handleClickAction = useCallback(
    (coords: Coords) => {
      if (energy === null || temperature === null) {
        return;
      }

      if (clickSessionStart.current === null) {
        startClickSession();
      }

      if (mode === 'egg') {
        if (energy < 1.0) {
          return;
        }

        const experienceGain = getExperienceBasedOnTemperature(temperature);

        handleAddExperience(experienceGain, 1.0, coords);
      }

      if (mode === 'owl') {
        if (energy < 1.0) {
          return;
        }

        handleAddExperience(0.0, 1.0, coords);
      }

      if (mode === 'dummy') {
        if (energy < 1.0) {
          return;
        }

        const kFactor = getKFactor(user);

        handleAddExperience(1.0 * kFactor, 1.0, coords);
      }

      clicks.current += 1;

      const start = clickSessionStart.current;

      if (!start) {
        return;
      }

      currentClickSession.current = {
        start: start.toISOString(),
        end: new Date().toISOString(),
        clicks: clicks.current,
      };

      debouncedSendActionsToServer({
        type: 'click',
        actions: [...clickSessions.current, currentClickSession.current],
        callback: () => {
          clearClickSessionStart();
          clearClickSessions();
          clearClicks();
        },
      });
    },
    [debouncedSendActionsToServer, handleAddExperience, energy, temperature, mode, user],
  );

  const handleHoldAction = useCallback(
    (effect: Object3D<Object3DEventMap> | undefined, holdCallback?: (time: number) => void) => {
      if (holdIntervalRef.current) {
        clearInterval(holdIntervalRef.current);
      }

      if (energy === null) {
        return;
      }

      if (mode === 'egg') {
        if (!holdSessionStart.current) {
          holdSessionStart.current = new Date();
        }

        holdIntervalRef.current = setInterval(() => {
          if (energy < 5.0) {
            return;
          }

          holds.current += 1;

          addTemperatureAndReduceEnergy(1.0, 5.0);

          if (holdCallback) {
            holdCallback(holds.current);
          }

          if (temperatureRef.current) {
            ParticleSystemUtil.updateHeatEffect(temperatureRef.current, effect);
          }
          const addHeatData = { x: 50, y: 50 };
          const addHeat = new CustomEvent('addHeat', { detail: addHeatData });
          document.dispatchEvent(addHeat);
        }, 1500);
      }
    },
    [addTemperatureAndReduceEnergy, energy, temperature, mode],
  );

  const handleHoldStopAction = useCallback(
    (effect?: Object3D<Object3DEventMap> | undefined) => {
      if (holdIntervalRef.current) {
        clearInterval(holdIntervalRef.current);

        if (mode === 'egg') {
          ParticleSystemUtil.updateHeatEffect(-1, effect);
        }

        if (holds.current === 0) {
          return;
        }

        const start = holdSessionStart.current;

        if (!start) {
          return;
        }

        if (start.getTime() === sentStartDate.current) {
          return;
        }

        sentStartDate.current = start.getTime();

        sendActionsToServer({
          type: 'hold',
          actions: [
            {
              start: start.toISOString(),
              end: new Date().toISOString(),
              holds: holds.current,
            },
          ],
          callback: () => {
            clearHoldSessionStart();
            clearHolds();
          },
        });
      }
    },
    [sendActionsToServer, mode],
  );

  const handleSwipeAction = useCallback(
    (
      scene: Object3D<Object3DEventMap>,
      effect: Object3D<Object3DEventMap> | undefined,
      batchedRenderer: BatchedRenderer,
      coords?: Coords,
      rotation?: number,
    ) => {
      if (energy === null) {
        return;
      }

      if (mode === 'owl') {
        if (energy < 10.0) {
          return;
        }

        addHappinessAndReduceEnergy(1.0, 10.0);

        ParticleSystemUtil.playEffect('HeartPoof', scene, effect, batchedRenderer);
      }

      if (mode === 'dummy') {
        if (energy < 5.0) {
          return;
        }

        const kFactor = getKFactor(user);

        handleAddExperience(5.0 * kFactor, 5.0, coords);

        ParticleSystemUtil.playEffect('ClawAttack', scene, effect, batchedRenderer, rotation);
      }

      if (!swipeSessionStart.current) {
        swipeSessionStart.current = new Date();
      }

      swipes.current += 1;

      const start = swipeSessionStart.current;

      if (!start) {
        return;
      }

      debouncedSendActionsToServer({
        type: 'swipe',
        actions: [
          {
            start: start.toISOString(),
            end: new Date().toISOString(),
            swipes: swipes.current,
          },
        ],
        callback: () => {
          clearSwipeSessionStart();
          clearSwipes();
        },
      });
    },
    [addHappinessAndReduceEnergy, debouncedSendActionsToServer, handleAddExperience, mode, user, energy],
  );

  useEffect(() => {
    temperatureRef.current = temperature;
  }, [temperature]);

  useEffect(() => {
    if (mode !== 'egg') {
      return;
    }

    clickSessions.current = [
      ...clickSessions.current,
      ...(currentClickSession.current !== null ? [currentClickSession.current] : []),
    ];

    clearCurrentClickSession();
    clearClickSessionStart();
    if (clicks.current !== 0) {
      clearClicks();
    }

    clickSessionStart.current = new Date();
  }, [mode, temperature]);

  useEffect(() => {
    const onContextMenuTriggered = (event: MouseEvent) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', onContextMenuTriggered);

    return () => {
      document.removeEventListener('contextmenu', onContextMenuTriggered);

      if (holdIntervalRef.current) {
        clearInterval(holdIntervalRef.current);
      }
    };
  }, []);

  return useMemo(
    () => ({
      handleClickAction,
      handleHoldAction,
      handleHoldStopAction,
      handleSwipeAction,
    }),
    [handleClickAction, handleHoldAction, handleHoldStopAction, handleSwipeAction],
  );
};
