export const fragmentShader = `
uniform float uTime;
uniform sampler2D map;
varying vec2 vUv;
varying vec3 vWorldPosition;
varying vec3 vNormal;

void main() {
  // Sample the texture
  vec4 texColor = texture2D(map, vUv);
  
  // Calculate fresnel effect
  vec3 viewDir = normalize(cameraPosition - vWorldPosition);
  float fresnel = pow(1.0 - abs(dot(viewDir, vNormal)), 3.0);
  
  // Time-based glow
  float glow = 0.5 + 0.5 * sin(uTime * 2.0);
  
  // Combine effects
  vec3 color = texColor.rgb + fresnel * vec3(0.5, 0.8, 1.0) + glow * vec3(0.4, 0.4, 0.4);
  
  // Set transparency based on fresnel and texture alpha
  float alpha = texColor.a * (0.4 + 0.4 * fresnel);
  
  gl_FragColor = vec4(color, alpha);
}
`;
