import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useGameStore } from '../../store';
import { useEffect } from 'react';
import axios from 'axios';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import lvlBg from '../../assets/icon-level-bg.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const FrenInfo = () => {
  const { id } = useParams(); // Extracts the id from the URL
  const { foreignOwl, setForeignOwl } = useGameStore();
  useTelegramBackButton('/leaderbird');
  useEffect(() => {
    const fetchFrenInfo = async () => {
      try {
        const response = await axios.get(`https://api.masonsplay.com/fren/info/${id}/`);

        setForeignOwl(response.data);
      } catch (error) {
        console.error('Error fetching Fren Info:', error);
      }
    };

    fetchFrenInfo();

    return () => {
      setForeignOwl(null);
    };
  }, [id]);

  if (!foreignOwl) {
    return null;
  }

  return (
    <Wrapper className="absolute z-2">
      <div className="flex flex-grow"></div>
      <div className="flex h-16">
        <div className="flex items-center text-white empires text-lg font-medium text-center">
          {foreignOwl.username ? (
            <b className="text-3xl capitalize">{foreignOwl.username}</b>
          ) : (
            <b>ID {foreignOwl.tg_id}</b>
          )}
          {
            <div
              className="w-[28px] h-[26px] pt-[1px] ml-2"
              style={{
                backgroundImage: `url(${lvlBg})`,
                backgroundSize: 'cover',
              }}
            >
              <div className="w-full text-center text-white font-bold text-[14px] leading-[24px]">
                {foreignOwl.level}
              </div>
            </div>
          }
        </div>
      </div>
      <div className="w-80 bg-[#140D2E]/[.6] rounded-2xl flex justify-between px-4 py-3 backdrop-blur">
        <div className="w-3/12 text-white flex flex-col justify-center items-center text-lg">
          <img className="w-6 h-6 rounded-full" src={`https://notwise.b-cdn.net/icons/strength.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">strength</span>
          <b>{foreignOwl.strength}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center text-lg">
          <img className="w-6 h-6 rounded-full" src={`https://notwise.b-cdn.net/icons/agility.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">agility</span>
          <b>{foreignOwl.agility}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center text-lg">
          <img className="w-6 h-6 rounded-full" src={`https://notwise.b-cdn.net/icons/wisdom.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">wisdom</span>
          <b>{foreignOwl.wisdom}</b>
        </div>
        <div className="w-3/12 text-white flex flex-col justify-center items-center text-lg">
          <img className="w-6 h-6 rounded-full" src={`https://notwise.b-cdn.net/icons/endurance.png`} alt="icon" />
          <span className="text-xs text-white/[60%] mt-1">endurance</span>
          <b>{foreignOwl.endurance}</b>
        </div>
      </div>
    </Wrapper>
  );
};
