import { useCallback, useEffect, useRef } from 'react';
import { useLogin, useRefreshToken } from '../api';
import { useTokenStore } from '../store/useTokenStore';
import { tg_init } from '../utils/telegramapi';
import toast from 'react-hot-toast';

const decodeJwt = (token: string): Record<'exp', number> => {
  const payloadBase64 = token.split('.')[1];
  const payloadDecoded = window.atob(payloadBase64);
  return JSON.parse(payloadDecoded);
};

export const useAuth = () => {
  const accessToken = useTokenStore((state) => state.accessToken);
  const setAccessToken = useTokenStore((state) => state.setAccessToken);

  const isMounted = useRef(false);
  const timer = useRef<NodeJS.Timeout | null>(null);

  const expirationDate = useRef<number | null>(null);

  const { login } = useLogin({
    onSuccess: (loginResponse) => {
      setTokenData(loginResponse.access_token);
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  const { refreshToken } = useRefreshToken();

  const setTokenData = useCallback(
    (token: string) => {
      const tokenData = decodeJwt(token);
      expirationDate.current = tokenData.exp;

      setAccessToken(token);
    },
    [setAccessToken],
  );

  const handleLogin = useCallback(() => {
    if (accessToken) {
      return;
    }

    login({
      data: {
        initData: tg_init,
      },
    });
  }, [accessToken, login]);

  const init = useCallback(() => {
    if (isMounted.current) {
      return;
    }
    isMounted.current = true;

    handleLogin();
  }, [handleLogin]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const expiresAt = expirationDate.current;

    if (expiresAt === null || !accessToken) {
      return;
    }

    const currentTime = new Date().getTime();

    const timeUntilExpiration = expiresAt * 1000 - currentTime;

    timer.current = setTimeout(async () => {
      refreshToken(
        {
          token: accessToken,
        },
        {
          onSuccess: (refreshResponse) => {
            setTokenData(refreshResponse.access_token);
          },
          onError: () => {
            toast.error('Something went wrong');
            setAccessToken(null);
            return;
          },
        },
      );
    }, timeUntilExpiration - 60 * 1000);

    return () => {
      timer.current && clearTimeout(timer.current);
    };
  }, [accessToken, refreshToken, setAccessToken, setTokenData]);
};
