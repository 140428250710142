import {Link, Outlet} from 'react-router-dom';

import { Round } from '../../utils/math';
import {MainNav, OverflowerText, Progressbar, SquadWidget, Thermometer, UpdateBarWidget} from '../../components';
import { useGameStore, useUserStore } from '../../store';
import { FuelDrawer } from '../../components/FuelDrawer/FuelDrawer';
import tgStar from '../../assets/icon-tg-star-gold.svg';
import { useEffect, useState } from 'react';
import { SESSION_STORAGE_DRAWER_KEY } from './Stage3';
import { FightAnnouncement } from '../../components/FightWidgets/FightAnnouncement';
import WebsocketIndicator from "../../components/WebSocketContext/WebsocketIndicator";
import {AudioPlayer} from "../../components/AudioPlayer/AudioPlayer";
import {LEVELS} from "../../config";

export const Stage1 = () => {
  const userInfo = useUserStore((state) => state.user);
  const tg = window.Telegram.WebApp;
  const { incomingBattleState } = useGameStore();

  const [isFuelDrawerOpen, setIsFuelDrawerOpen] = useState(false);

  const fuelUp = () => {
    setIsFuelDrawerOpen(true);
  };

  useEffect(() => {
    if (userInfo.energy === null) {
      return;
    }

    const isShown = localStorage.getItem(SESSION_STORAGE_DRAWER_KEY);

    if (userInfo.energy > 0 && isShown) {
      localStorage.removeItem(SESSION_STORAGE_DRAWER_KEY);
    }

    if (userInfo.energy <= 1) {
      if (isShown) {
        return;
      }

      setIsFuelDrawerOpen(true);
      localStorage.setItem(SESSION_STORAGE_DRAWER_KEY, 'true');
    }
  }, [userInfo.energy]);

  if (userInfo.energy === null || userInfo.temperature === null) {
    return null;
  }

  const levelPercentage = Math.round(
    ((userInfo.totalExp - LEVELS[userInfo.level]) /
      (LEVELS[userInfo.level + 1] - LEVELS[userInfo.level])) *
    100,
  );

  return (
    <>
      <div className="w-full absolute webapp-safe-top z-1">
        <div className={'absolute w-11/12 mb-6 z-10 flex flex-col left-1/2 transform -translate-x-1/2'}>
          <div className="flex relative w-full justify-between items-center">
            <div className="relative flex p-0.5 bg-gradient-to-b from-[#FF9A46] to-[#F4C9A5] rounded-full justify-center items-center">
              <img
                src={userInfo?.avatar ? userInfo.avatar : '/assets/images/avatar.png'}
                className="rounded-full block w-12 h-12"
                alt={'avatar'}
              />
              <span className="absolute -bottom-1 left-0">
                  <svg width="24" height="24" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.3046 2.76465C13.921 0.816768 17.5056 0.816768 20.122 2.76464L20.5112 3.05441C21.2682 3.61799 22.1262 4.0312 23.0388 4.27166L23.508 4.3953C26.6622 5.2264 28.8972 8.02895 29.0056 11.289L29.0217 11.774C29.0531 12.7172 29.265 13.6457 29.646 14.5091L29.8419 14.9531C31.1587 17.9373 30.3611 21.432 27.8798 23.5494L27.5107 23.8644C26.7928 24.477 26.199 25.2216 25.7615 26.0578L25.5366 26.4877C24.0244 29.3779 20.7948 30.9332 17.5924 30.3134L17.116 30.2212C16.1894 30.0419 15.2371 30.0419 14.3105 30.2212L13.8341 30.3134C10.6317 30.9332 7.40206 29.3779 5.88992 26.4877L5.66497 26.0578C5.22747 25.2216 4.63369 24.477 3.91579 23.8644L3.54668 23.5494C1.06543 21.432 0.267787 17.9373 1.58462 14.9531L1.78052 14.5091C2.16152 13.6457 2.37344 12.7172 2.40479 11.774L2.42092 11.289C2.5293 8.02895 4.76426 5.2264 7.91847 4.3953L8.3877 4.27166C9.3003 4.0312 10.1583 3.61799 10.9153 3.05442L11.3046 2.76465Z"
                      fill="url(#paint0_linear_185_4292)"
                      stroke="white"
                      strokeWidth="0.824721"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_185_4292"
                        x1="5.5183"
                        y1="4.40184"
                        x2="23.9278"
                        y2="33.0183"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF7B43" />
                        <stop offset="1" stopColor="#FFE04E" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className={`absolute text-white font-bold top-0.5 w-6 text-sm text-center`}>
                    {userInfo.level}
                  </span>
                </span>
            </div>
            <div className="flex flex-col justify-center items-start ml-3 flex-grow">
              <div className="flex items-center gap-2 text-white font-medium text-sm justify-between">
                {userInfo.totalExp.toLocaleString()} EXP <span className="text-white/[.6] -mt-1">⚬</span>{' '}
                {!isNaN(levelPercentage) ? levelPercentage + '%' : 'LVL MAX'}
              </div>
              <p className="inline-flex items-center text-sm font-normal text-white/[.4]">
                <SquadWidget squadInfo={userInfo.squad}/>
              </p>
            </div>
            <div className="text-white flex justify-center items-center">
              <AudioPlayer />
            </div>
          </div>
        </div>
        {
          <Link
            to="/leaderbird"
            className="flex flex-col items-center pointer-events-auto mt-24"
          >
            <div className="leaderbird w-max flex justify-between items-center text-white">
              <span className="nw-icon-wingl text-3xl text-white/[.6]"></span>
              <span className="mx-2 font-medium opacity-90">#{userInfo.rank}</span>
              <span className="nw-icon-wingr text-3xl text-white/[.6]"></span>
            </div>
          </Link>
        }
        <Outlet />
      </div>
      <OverflowerText className="text-white absolute" />
      <div className="absolute top-[50%] -translate-y-1/2 left-5 rounded-full backdrop-blur p-2 pb-4">
        <Thermometer
          barcolor="bg-gradient-to-t from-[#FF5741FF] to-[#ff0000]"
          ht="h-48"
          percent="h"
          spark={false}
          value={userInfo?.temperature}
          maxvalue={20}
          customStyle={{
            height: `${Math.min(100, Round((userInfo.temperature / 20) * 100, 1))}%`,
          }}
        />
      </div>

      <div className="w-full absolute z-5 bottom-28 flex items-center flex-col justify-between z-5">
        {userInfo.energy <= 50 && (
          <button
            className="mb-4 rounded-2xl py-3 px-4 space-x-2.5 flex w-fit bg-[#00000059] active:bg-[#0000008c]"
            onClick={fuelUp}
          >
            <div className="text-white text-[16px] flex font-semibold">⚡️ Fuel up</div>
            <div
              className="flex items-center space-x-1 py-[2px] px-[7px] rounded-[12px]"
              style={{
                background:
                  'var(linear-gradient(130deg, rgba(253, 210, 26, 0.20) -21.52%, rgba(228, 123, 3, 0.20) 88.07%))',
                backdropFilter: 'blur(10px)',
              }}
            >
              <img src={tgStar} alt="tg-star" />
              <div
                className="text-[12px] font-semibold"
                style={{
                  color: '#FDD21A',
                }}
              >
                30
              </div>
            </div>
          </button>
        )}

        <div className="w-full flex items-center justify-between rounded-xl px-5">
          <div className="w-4/12 flex items-center">
            {userInfo.energy === 0 ? (
              <span className="text-2xl self-center">🪫️</span>
            ) : (
              <span className="text-2xl">⚡</span>
            )}
            <span className="text-white font-medium ml-1 text-sm leading-3">
              <span className="text-xl font-bold">{userInfo.energy}</span>
              <br />
              <span className="opacity-70">/ 500</span>
            </span>
          </div>
          <Progressbar
            blurred
            winking={false}
            barcolor="bg-gradient-to-r from-[#FF6F41] via-[#FFAD42] to-[#FFE34F]"
            ht="min-h-3"
            percent="w"
            spark={false}
            value={userInfo.energy}
            maxvalue={500}
            customStyle={{ width: `${Round(userInfo.energy / 5, 1)}%` }}
          />
        </div>
      </div>
      <MainNav
        isBranch
        gameMode="normal"
        setIsNeedShown={false}
        setGameMode={false}
        userExp={userInfo.totalExp}
        skillPoints={userInfo.skillPoints}
      />
      <FuelDrawer isOpen={isFuelDrawerOpen} setIsOpen={setIsFuelDrawerOpen} />

      {incomingBattleState && <FightAnnouncement />}
      <WebsocketIndicator/>
    </>
  );
};
