import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import { useGetAchievement } from '../../api/useGetAchievement';

export const LottiePlayer = ({
  preloaderStyle,
  lottieUrl,
  isLocked,
  lockedReason,
  isLoop,
}: {
  preloaderStyle: string | null;
  lottieUrl: string;
  isLocked?: boolean;
  lockedReason?: string;
  isLoop?: boolean;
}) => {
  const { data, isLoading } = useGetAchievement(lottieUrl, 'blob', {
    staleTime: Infinity,
  });

  if (isLoading) {
    return (
      <div
        className={`${
          preloaderStyle === 'round' ? 'rounded-full' : 'rounded-xl'
        } w-full aspect-[1/1] bg-slate-700 animate-pulse`}
      />
    ); // Show a loading state while the animation is being fetched
  }

  return (
    <div className="relative rounded-xl overflow-hidden">
      <DotLottieReact src={data} width={100} height={100} loop={isLoop} autoplay />
      {isLoading && <div className="w-full aspect-[1/1] bg-slate-700 animate-pulse" />}

      {isLocked && data && (
        <div className="absolute top-0 w-full h-full flex flex-col justify-center items-center bg-[#0F152A]/[.8] backdrop-blur p-1">
          <span className="nw-icon-locked text-3xl text-white"></span>
          <div className="absolute bottom-3 text-[10px] font-bold">{lockedReason}</div>
        </div>
      )}
    </div>
  );
};
