import { QueryCallback, useMutation } from './useMutation';

export type AccessToken = {
  access_token: string;
};

export const useLogin = ({ onSuccess, onError }: QueryCallback) => {
  const { mutate, ...rest } = useMutation<AccessToken>({
    url: 'login',
    method: 'post',
    onSuccess,
    onError,
  });

  return { login: mutate, ...rest };
};
