import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";
import { useGameStore } from "../../store";
import styled from "styled-components";
import { FightOverlay } from "../../components/FightWidgets/FightOverlay/FightOverlay";

const showDebugOverlay = 0;

export const Fight = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { battleState, battleSpoils } = useGameStore();

  useEffect(() => {
    if (location.pathname === '/fight' && !battleState && !battleSpoils) {
      navigate('/');
    }
  }, [location, battleState]);

  return (
    <>
      {showDebugOverlay && (
        <DebugOverlay>
          <Row>
            <A>Is in battle?</A><B>{battleState ? 'Yes' : 'No'}</B>
          </Row>
          <RowBreak />
          <Row>
            <A>Round</A><B>{battleState?.round ?? '-'}</B>
          </Row>
          <Row>
            <A>Deadline</A><B>{battleState?.deadline ?? '-'}</B>
          </Row>
          <Row>
            <A>Player 1</A><B>{battleState?.user1.username ?? 'Missing'}</B>
          </Row>
          <Row>
            <A>P1 HP</A><B>{battleState?.user1.hp ?? '-'} / {battleState?.user1.maxHp ?? '-'}</B>
          </Row>
          <Row>
            <A>Player 2</A><B>{battleState?.user2.username ?? 'Missing'}</B>
          </Row>
          <Row>
            <A>P2 HP</A><B>{battleState?.user2.hp ?? '-'} / {battleState?.user2.maxHp ?? '-'}</B>
          </Row>
          <RowBreak />
          <Row>
            <A>Finished battle?</A><B>{battleSpoils ? 'Yes' : 'No'}</B>
          </Row>
          <Row>
            <A>EXP</A><B>{battleSpoils ? battleSpoils.exp : '-'}</B>
          </Row>
          <Row>
            <A>Gold</A><B>{battleSpoils ? battleSpoils.coins : '-'}</B>
          </Row>
        </DebugOverlay>
      )}

      <FightOverlay />
    </>
  );
};

const RowBreak = styled.div`
  width: 90%;
  margin: 8px 5%;
  height: 1px;
  border-bottom: 1px solid #333333;
`;

const A = styled.span`
  flex: 1;
  text-align: left;
  opacity: 0.5;
`;

const B = styled.span`
  flex: 1;
  text-align: right;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const DebugOverlay = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: #000000;
  padding: 16px;
  border-radius: 4px;
  width: 340px;
  text-align: center;
`;
