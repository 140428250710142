import { useEffect, useRef, useState } from 'react';
import { useAnimations } from '@react-three/drei';
import { Object3D, Vector3 as ThreeVector3 } from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import { STAGES } from '../../../config';
import { handleAnimation } from '../../../utils';
import { ENERGY_TO_REDUCE_ON_HOLD, useUserAction } from '../../../hooks';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem, QuarksUtil } from 'three.quarks';
import { ParticleSystemUtil } from '../particles/ParticleSystemUtil';
import { MODEL_URLS } from '../../../constants';
import { useGLTF } from '../hooks/useGLTF';
import { useUserStore } from '../../../store';
import { getLayerByRenderOrder } from './composition/Layer';
import { useBindObject3DToLayer } from '../hooks/useBindObject3DToLayer';
import {tg_haptic} from "../../../utils/telegramapi";
import { AudioSystemUtil } from '../particles/AudioSystemUtil';

export type Coords = { x: number; y: number };

export const EggModel = () => {
  const { scene: mainScene } = useThree();
  const spark = useRef<Object3D>();
  const heat = useRef<Object3D>();
  const bgParticles = useRef<Object3D>();
  const levelUp = useRef<Object3D>();
  const batchedRenderer = useRef(new BatchedRenderer());
  const [particlesReady, setParticlesReady] = useState(false);
  const { scene, animations } = useGLTF(MODEL_URLS.egg);
  const { actions } = useAnimations(animations, scene);
  const { handleClickAction, handleHoldAction, handleHoldStopAction } = useUserAction('egg');
  const { user: userInfo, intermediate } = useUserStore();
  const energy = useUserStore((state) => state.user.energy);
  const { totalExp } = userInfo;

  const quarksEffectEgg = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise1_effect);
  const quarksEffectOwl = ParticleSystemUtil.useQuarks(MODEL_URLS.notwise2_effect);

  const lacksHoldEnergy = energy === null || energy < ENERGY_TO_REDUCE_ON_HOLD;

  const handleClick = (event: MouseEvent) => {
    handleClickAction(event);
    handleAnimation({
      target: scene,
      model: 'egg',
      action: actions['Egg_locator_01|Take 001|BaseLayer'],
      animationName: 'anim_Tap_01',
      isLoop: false,
    });
    tg_haptic.impactOccurred('light');
    ParticleSystemUtil.playEffect('Spark', scene, spark.current, batchedRenderer.current);
  };

  useBindObject3DToLayer(batchedRenderer.current, getLayerByRenderOrder(0));

  const bgParticlesCurrentEffect = useRef<Object3D>();

  const handlePointerUp = () => {
    document.removeEventListener('pointerup', handlePointerUp);

    handleHoldStopAction(heat.current);

    AudioSystemUtil.stop('sfx-heating');

    if (bgParticlesCurrentEffect.current) {
      QuarksUtil.endEmit(bgParticlesCurrentEffect.current);
    }
  };

  const handlePointerDown = (event: MouseEvent) => {
    event.stopPropagation();

    handleClick(event);
    handleHoldAction(heat.current, (time) => {
      if (time === 1) {
        tg_haptic.impactOccurred('light');
        bgParticlesCurrentEffect.current = ParticleSystemUtil.playEffect(
          'EggContinuousParticles',
          scene,
          bgParticles.current,
          batchedRenderer.current,
        );

        AudioSystemUtil.play('sfx-heating', true);
      }
    });

    document.addEventListener('pointerup', handlePointerUp);
  };

  useEffect(() => {
    if (lacksHoldEnergy) {
      handlePointerUp();
    }
  }, [handleHoldStopAction, lacksHoldEnergy]);

  useEffect(() => {
    if (!quarksEffectEgg || !quarksEffectOwl) {
      return;
    }

    spark.current = quarksEffectEgg.getObjectByName('Spark')!;
    heat.current = quarksEffectEgg.getObjectByName('EggHeat')!;
    bgParticles.current = quarksEffectEgg.getObjectByName('EggContinuousParticles')!;
    levelUp.current = quarksEffectOwl.getObjectByName('LevelUp')!;

    ParticleSystemUtil.prepareEffect('Spark', spark.current, new ThreeVector3(-0.2, -1.2, 0));
    ParticleSystemUtil.prepareEffect('LevelUp', levelUp.current);
    ParticleSystemUtil.prepareEffect('EggContinuousParticles', bgParticles.current, new ThreeVector3(-0.2, 0, 0), 2);

    heat.current.position.set(0, 5.5, 0);
    heat.current.traverse((obj: Object3D) => {
      if (obj instanceof ParticleEmitter) {
        batchedRenderer.current.addSystem(obj.system);
        (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
      }
    });

    setParticlesReady(true);
  }, [quarksEffectOwl, quarksEffectEgg]);

  useEffect(() => {
    handleAnimation({
      target: scene,
      model: 'egg',
      action: actions['Egg_locator_01|Take 001|BaseLayer'],
      animationName: 'anim_Tap_02',
      isLoop: false,
    });
  }, [scene, actions]);

  useEffect(() => {
    if (totalExp >= STAGES[2]) {
      // Triggers manual hold stop action when stage changes
      handleHoldStopAction();
    }
  }, [handleHoldStopAction, totalExp]);

  useEffect(() => {
    if (!levelUp.current || intermediate.levelUps === 0) {
      return;
    }

    const timeouts: NodeJS.Timeout[] = [];

    for (let i = 0; i < intermediate.levelUps; i++) {
      timeouts.push(
        setTimeout(() => {
          ParticleSystemUtil.playEffect('LevelUp', mainScene, levelUp.current, batchedRenderer.current);

          if (i === intermediate.levelUps - 1) {
            useUserStore.setState((state) => ({
              ...state,
              intermediate: {
                ...state.intermediate,
                levelUps: 0
              }
            }));
          }
        }, i * 300)
      );
    }

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [intermediate.levelUps, levelUp.current, particlesReady]);

  useFrame((_, delta) => {
    batchedRenderer.current.update(delta);
  });

  return (
    <primitive
      object={scene}
      onPointerDown={handlePointerDown}
    />
  );
};
