import { create } from 'zustand';
import { Coords } from '../hooks';
import { MarkerPosition } from '../components/GameSceneNew/hooks/owlControls/useBattleOwlControls';

export type BattleState = {
  uuid: string;
  round: number;
  deadline: number;
  user1: {
    username: string;
    hp: number;
    maxHp: number;
    damage: number;
    attack: MarkerPosition | null;
    defence: MarkerPosition | null;
    skin: {
      img: string;
    } | null;
    avatar: string | null;
  };
  user2: {
    username: string;
    hp: number;
    maxHp: number;
    damage: number;
    attack: MarkerPosition | null;
    defence: MarkerPosition | null;
    skin: {
      img: string;
    } | null;
    avatar: string | null;
  };
}

export type WebSocketBattleState = {
  uuid: string;
  round: number;
  deadline: number;
  user1: {
    tgid: string;
    max_hp: number;
    hp: number;
    strength: number;
    wisdom: number;
    agility: number;
    damage: number;
    attack: string;
    defence: string;
    critical: boolean;
    evaded: boolean;
  },
  user2: {
    tgid: string;
    max_hp: number;
    hp: number;
    strength: number;
    wisdom: number;
    agility: number;
    damage: number;
    attack: string;
    defence: string;
    critical: boolean;
    evaded: boolean;
  }
}

type CurrentBattleBattleState = {
  uuid: string;
  round: number;
  deadline: number;
  user1: {
    username: string;
    avatar: string;
    skin: {
      img: string;
    } | null;
    max_hp: number;
    current_hp: number;
  };
  user2: {
    username: string;
    avatar: string;
    skin: {
      img: string;
    } | null;
    max_hp: number;
    current_hp: number;
  };
}

export type BattleSpoils = {
  coins: number;
  exp: number;
}

export type BattleUser = {
  username: string;
  avatar: string | null;
  skin: {
    img: string;
  } | null;
  maxHp: number;
};

interface GameRef {
  gameRef: any;
  isTouch: boolean;
  load: number;
  gameStage: number;
  gameMode: string;
  animating: boolean;
  foreignOwl: any;
  isNeedsVisible: boolean;
  needsPosition: Coords | null;
  currentPointerCoords: Coords | null;
  battleState: BattleState | null;
  incomingBattleState: WebSocketBattleState | null;
  battleError: string | null;
  battleSpoils: BattleSpoils | null;
  battleUser1: Partial<BattleUser> | null;
  battleUser2: Partial<BattleUser> | null;
  isBlocked: boolean;
  setNeedsPosition: (cords: Coords | null) => void;
  setNeedsVisibility: (isNeedsVisible: boolean) => void;
  setGameRef: (gameRef: any) => void;
  setIsTouch: (isTouch: boolean) => void;
  setLoad: (load: number) => void;
  setIsBlocked: (isBlocked: boolean) => void;
  setForeignOwl: (foreignOwl: any) => void;
  setGameStage: (gameStage: number) => void;
  setGameMode: (gameMode: string) => void;
  setAnimating: (animating: boolean) => void;
  setCurrentPointerCoords: (coords: Coords | null) => void;
  setBattleState: (battleState: WebSocketBattleState | CurrentBattleBattleState | null) => void;
  setIncomingBattleState: (battleState: WebSocketBattleState | null) => void;
  setBattleError: (battleError: string | null) => void;
  setBattleSpoils: (battleSpoils: BattleSpoils | null) => void;
  setBattleUser1: (battleUser1: Partial<BattleUser> | null) => void;
  setBattleUser2: (battleUser2: Partial<BattleUser> | null) => void;
}

export const useGameStore = create<GameRef>((set, get) => ({
  gameRef: null,
  isTouch: false,
  load: 0,
  gameStage: 1,
  gameMode: '',
  foreignOwl: null,
  animating: true,
  isNeedsVisible: true,
  needsPosition: null,
  currentPointerCoords: null,
  battleState: null,
  incomingBattleState: null,
  battleError: null,
  battleSpoils: null,
  battleUser1: null,
  battleUser2: null,
  isBlocked: false,
  setNeedsPosition: (needsPosition) => set({ needsPosition }),
  setNeedsVisibility: (isNeedsVisible) => set({ isNeedsVisible }),
  setGameRef: (gameRef) => set({ gameRef }),
  setIsTouch: (isTouch) => set({ isTouch }),
  setLoad: (load) => set({ load }),
  setIsBlocked: (isBlocked) => set({ isBlocked }),
  setForeignOwl: (foreignOwl) => set({ foreignOwl }),
  setGameStage: (gameStage) => set({ gameStage }),
  setGameMode: (gameMode) => set({ gameMode }),
  setAnimating: (animating) => set({ animating }),
  setCurrentPointerCoords: (coords: Coords | null) => set({ currentPointerCoords: coords }),
  setBattleState: (source) => {
    if (!source) {
      set({ battleState: null });
      return;
    }

    const { battleUser1, battleUser2 } = get();

    if ((source as CurrentBattleBattleState).user1.username) {
      const battleState = source as CurrentBattleBattleState;

      set({
        battleState: {
          uuid: battleState.uuid,
          round: battleState.round,
          deadline: battleState.deadline,
          user1: {
            username: battleState.user1.username ?? 'MissingNo',
            maxHp: battleState.user1.max_hp ?? battleUser1?.maxHp,
            hp: battleState.user1.current_hp,
            attack: null,
            defence: null,
            damage: 0,
            avatar: battleState.user1.avatar,
            skin: battleState.user1.skin,
          },
          user2: {
            username: battleState.user2.username ?? 'MissingNo',
            maxHp: battleState.user2.max_hp ?? battleUser1?.maxHp,
            hp: battleState.user2.current_hp,
            attack: null,
            defence: null,
            damage: 0,
            avatar: battleState.user2.avatar,
            skin: battleState.user2.skin,
          },
        },
      });
    } else {
      const battleState = source as WebSocketBattleState;

      set({
        battleState: {
          uuid: battleState.uuid,
          round: battleState.round,
          deadline: battleState.deadline,
          user1: {
            username: battleUser1?.username || '',
            maxHp: battleState.user1.max_hp ?? battleUser1?.maxHp,
            hp: battleState.user1.hp,
            attack: battleState.user1.attack as MarkerPosition,
            defence: battleState.user1.defence as MarkerPosition,
            damage: battleState.user1.damage,
            avatar: battleUser1?.avatar || '',
            skin: battleUser1?.skin || null,
          },
          user2: {
            username: battleUser2?.username || '',
            maxHp: battleState.user2.max_hp ?? battleUser2?.maxHp,
            hp: battleState.user2.hp,
            attack: battleState.user2.attack as MarkerPosition,
            defence: battleState.user2.defence as MarkerPosition,
            damage: battleState.user2.damage,
            avatar: battleUser2?.avatar || '',
            skin: battleUser2?.skin || null,
          },
        },
      });
    }
  },
  setIncomingBattleState: (incomingBattleState) => set({ incomingBattleState }),
  setBattleError: (battleError) => set({ battleError }),
  setBattleSpoils: (battleSpoils) => set({ battleSpoils }),
  setBattleUser1: (battleUser1) => {
    const { battleState } = get();

    if (battleState) {
      const updatedBattleState = { ...battleState };

      updatedBattleState.user1.username = battleUser1?.username || '';
      updatedBattleState.user1.avatar = battleUser1?.avatar || '';
      updatedBattleState.user1.skin = battleUser1?.skin || null;
      updatedBattleState.user1.maxHp = battleUser1?.maxHp || 0;

      set({ battleUser1, battleState: updatedBattleState });
    } else {
      set({ battleUser1 });
    }
  },
  setBattleUser2: (battleUser2) => {
    const { battleState } = get();

    if (battleState) {
      const updatedBattleState = { ...battleState };

      updatedBattleState.user2.username = battleUser2?.username || '';
      updatedBattleState.user2.avatar = battleUser2?.avatar || '';
      updatedBattleState.user2.skin = battleUser2?.skin || null;
      updatedBattleState.user2.maxHp = battleUser2?.maxHp || 0;

      set({ battleUser2, battleState: updatedBattleState });
    } else {
      set({ battleUser2 });
    }
  },
}));
