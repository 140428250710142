import React from 'react';
import { Stage } from '../Stage';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';

export const Home = () => {
  useTelegramBackButton();

  return (
    <>
      <Stage />
    </>
  );
};
