import { useQuery } from './useQuery';
import { QueryObserverOptions } from '@tanstack/react-query';

interface Fren {
  tg_id: number;
  username: string;
  earned_for_referrer: number;
  frens_count: number | null;
  avatar: string | null;
}
interface FrenList {
  referer: Fren;
  frens: Fren[];
}

export const useGetFrens = (options: Partial<QueryObserverOptions<any, any, any>>) => {
  const { data, ...rest } = useQuery<FrenList>({
    url: 'frens/',
    method: 'get',
    options,
  });

  return { data, ...rest };
};
