import React, { MouseEventHandler, useRef, useState } from 'react';
import { AudioSystemUtil } from '../GameSceneNew/particles/AudioSystemUtil';

export const AudioPlayer = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handlePlayPause: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation();

    if (audioRef.current) {
      if (isPlaying) {
        AudioSystemUtil.stopAll();
      } else {
        AudioSystemUtil.resumeLatest();
      }

      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <button className="flex items-center px-3 py-2 bg-white/[.2] backdrop-blur text-sm font-semibold rounded-xl pointer-events-auto" onClick={handlePlayPause}>
        {isPlaying ? <span className={`nw-icon-sound text-[1.7rem]`}></span> : <span className={`nw-icon-no-sound text-[1.7rem]`}></span>}
      </button>
    </>
  );
};
