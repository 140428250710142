import { REACT_APP_BACKEND_URL } from '../config';
import axios, { AxiosRequestConfig } from 'axios';
import { useTokenStore } from '../store/useTokenStore';
import { useMutation as useReactMutation } from '@tanstack/react-query';

type Input = Partial<{
  params: AxiosRequestConfig['params'];
  data: AxiosRequestConfig['data'];
  token: string;
}>;

type Params = {
  url: string;
  method: AxiosRequestConfig['method'];
};

const mutationFn = async <T,>({
  url,
  method,
  input,
  token,
}: Params & {
  input: Input;
  token: string | null;
}) => {
  const response = await axios<T>({
    method,
    url: `${REACT_APP_BACKEND_URL}/${url}`,
    params: input?.params,
    data: input?.data,
    headers: { Authorization: `Bearer ${input?.token ?? token}` },
    withCredentials: true,
  });

  return response.data;
};

export type QueryCallback = {
  onSuccess?: (data?: AxiosRequestConfig['data']) => void;
  onError?: (error: unknown) => void;
};

export const useMutation = <T,>({ url, method, onSuccess, onError }: Params & QueryCallback) => {
  const token = useTokenStore((state) => state.accessToken);

  return useReactMutation<T, unknown, Input>({
    mutationFn: (input) => {
      return mutationFn({
        url,
        method,
        input,
        token,
      });
    },
    onSuccess,
    onError,
  });
};
