import styled from "styled-components";

export const OwlStats = ({
  owl
}: {
  owl: {
    username: string | null;
    hp: number | null;
    maxHp: number | null;
    skin: {
      img: string;
    } | null;
  } | null;
}) => {
  return (
    <OwlStatsContainer>
      <AvatarContainer $pending={!owl?.username}>
        <Avatar $url={owl?.skin?.img ?? null} />
      </AvatarContainer>
      <DetailsContainer>
        <Username $pending={!owl?.username}>
          {owl?.username ?? <Loader />}
        </Username>
        <HealthBar
          $pending={!owl?.maxHp}
          $hpPercentage={(owl?.hp ?? 0.0) / (owl?.maxHp ?? 1.0)}
        >
          <HealthContainer $pending={!owl?.maxHp}>
            {owl?.hp} / {owl?.maxHp}
          </HealthContainer>
        </HealthBar>
      </DetailsContainer>
    </OwlStatsContainer>
  );
};

const Loader = styled.div`
  position: relative;
  width: 100%;
  height: 24px;
  border-radius: 16px;
  background-color: #ffffff66;
  animation: OwlStatsPending 1.0s infinite alternate;
`;

const HealthContainer = styled.div<{ $pending: boolean }>`
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  color: #aaaaaa;
  transition: opacity 0.5s, transform 0.5s, filter 0.5s;

  ${({ $pending }) => $pending ? `
    opacity: 0.0;
    transform: translateX(-8px);
    filter: blur(2px);
  ` : `
    opacity: 1.0;
    transform: translateX(0px);
    filter: blur(0px);
  `}
`;

const HealthBar = styled.div<{
  $pending: boolean;
  $hpPercentage: number;
}>`
  position: relative;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #ffffff33;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${({ $hpPercentage }) => $hpPercentage < 0.25 ? '#f51439' : '#f0bb57'};
    border-radius: 4px;
    transition: width 0.5s;
  }

  ${({ $pending, $hpPercentage }) => $pending ? `
    &::after {
      width: 0%;
    }
  ` : `
    &::after {
      width: calc(100% * ${$hpPercentage});
    }
  `}
`;

const Username = styled.div<{ $pending: boolean }>`
  color: #ffffff;
  font-weight: bold;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
`;

const Avatar = styled.div<{ $url: string | null }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-shrink: 0;
  width: 42px;
  height: 42px;
  background-image: url(${props => props.$url});
  background-size: cover;
  border-radius: 50%;
  overflow: hidden;
`;

const AvatarContainer = styled.div<{ $pending: boolean }>`
  position: relative;
  width: 48px;
  height: 48px;
  background-color: #f0bb57;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  ${({ $pending }) => $pending ? `
    background-color: #ffffff66;
    animation: OwlStatsPending 1.0s infinite alternate;
  ` : ''}
`;

const OwlStatsContainer = styled.div`
  @keyframes OwlStatsPending {
    from {
      opacity: 0.5;
    }

    to {
      opacity: 1.0;
    }
  }

  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  width: 160px;
  flex-shrink: 0;
`;