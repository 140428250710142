import { useUserStore } from '../../store';
import { useGetLeaders } from '../../api/useGetLeaders';
import { Leader } from './Leader';
import Starfield from '../../components/Starfield';
import { useTelegramBackButton } from '../../hooks/useTelegramBackButton';
import { LottiePlayer } from '../../components';

export const LeaderBird = () => {
  const userInfo = useUserStore((state) => state.user);
  useTelegramBackButton('/');
  const { data, isLoading } = useGetLeaders();
  const leaders = data?.slice(0, 3) ?? [];
  const rest = data?.slice(3) ?? [];

  return (
    <div className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-scroll no-scrollbar pointer-events-auto">
      <Starfield amount={50} />
      <div className="absolute w-[252px] h-[204px] bg-[#513ba738] blur-[60px] mx-auto left-0 right-0 top-24 rounded-[50%] z-0"></div>
      <div className="flex flex-col items-center px-5 z-10 webapp-safe-top">
        <div className="w-[68px] h-[68px] mt-5">
          <LottiePlayer
            lottieUrl={'https://cdn.notwise.co/lottie/crown.lottie'}
            isLoop={false}
            preloaderStyle={'round'}
          />
        </div>
        <div className="text-2xl font-bold text-white mt-4">Exp Leaderbrid</div>
        <div className="text-[#E3EAF699] text-center">The most experienced Owls</div>
      </div>

      <div className="w-full flex justify-between text-xs text-[#E3EAF699] mb-5 px-4  mt-10">
        <div>Name</div>
        <div>Rank</div>
      </div>
      <div className="flex flex-col mb-20 px-3">
        <div
          className="rounded-[24px] border-[0.6px] border-[#20263C] flex flex-col space-y-6 p-3 pr-[18px]"
          style={{
            background: 'linear-gradient(341deg, rgba(35, 49, 86, 0.23) 24.15%, rgba(81, 74, 131, 0.23) 79.96%)',
            backdropFilter: 'blur(10px)',
          }}
        >
          {isLoading
            ? [1, 2, 3].map((item, idx) => <Leader showArrow={false} tgId={0} key={idx} isLoading />)
            : leaders.map(({ username, avatar, experience, level, tg_id }, idx) => (
                <Leader
                  showArrow={username !== userInfo.username}
                  key={idx}
                  name={username}
                  rank={idx + 1}
                  experience={experience}
                  level={level ?? 1}
                  tgId={tg_id}
                  avatar={avatar ?? '/assets/images/avatar.png'}
                />
              ))}
        </div>
        <div className="p-3 pr-[18px] flex flex-col space-y-6">
          {isLoading
            ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item, idx) => (
                <Leader showArrow={false} tgId={0} key={idx} isLoading />
              ))
            : rest.map(({ username, experience, avatar, level, rank, tg_id }, idx) => (
                <Leader
                  showArrow={username !== userInfo.username}
                  key={idx}
                  name={username}
                  rank={idx + 4}
                  experience={experience}
                  tgId={tg_id}
                  avatar={avatar ?? '/assets/images/avatar.png'}
                  level={level ?? 1}
                />
              ))}
        </div>
      </div>

      <div
        className="fixed bottom-8 rounded-[24px] border-[0.6px] border-[rgba(32, 38, 60, 0.50)] p-3 pr-[18px] left-3 right-[18px]"
        style={{
          background: 'linear-gradient(341deg, rgba(35, 49, 86, 0.23) 24.15%, rgba(81, 74, 131, 0.23) 79.96%)',
          backdropFilter: 'blur(28px)',
        }}
      >
        <Leader
          showArrow={false}
          name={userInfo.username ?? ''}
          rank={userInfo.rank}
          experience={userInfo.totalExp}
          tgId={userInfo.tg_id}
          avatar={userInfo.avatar ?? '/assets/images/avatar.png'}
          level={userInfo.level}
        />
      </div>
    </div>
  );
};
