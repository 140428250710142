import { useMutation } from './useMutation';

export const useBuyGoods = () => {
  const { mutate, ...rest } = useMutation({
    url: 'buy-goods/',
    method: 'post',
  });

  return { buyGoods: mutate, ...rest };
};
