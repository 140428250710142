import { useMutation } from './useMutation';
import { AccessToken } from './useLogin';

export const useRefreshToken = () => {
  const { mutate, ...rest } = useMutation<AccessToken>({
    url: 'refresh',
    method: 'post',
  });

  return { refreshToken: mutate, ...rest };
};
