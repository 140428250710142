import * as THREE from 'three';
import { CDN_GATEWAY_BASE } from '../../../config';
import { useTexture } from '@react-three/drei';
import { useRef } from 'react';
import { useDayNightCycle } from '../hooks/useDayNightCycle';

export const Skybox = ({
  position
}: {
  position: [number, number, number];
}) => {
  const moonRef = useRef<THREE.Mesh>(null!);
  const moonTexture = useTexture(`${CDN_GATEWAY_BASE}/images/bg-moon.png`, texture => {
    texture.colorSpace = THREE.SRGBColorSpace;
    texture.needsUpdate = true;
  });

  const updateMoon = ({ time, daynightCycle }: { time: number, daynightCycle: number }) => {
    if (!moonRef.current) {
      return;
    }

    moonRef.current.position.set(
      -10.0 * Math.cos(daynightCycle) - 5.0,
      15.0 * daynightCycle,
      0.01
    );
    moonRef.current.scale.set(
      Math.sin(daynightCycle) + 0.5,
      Math.sin(daynightCycle) + 0.5,
      1.0
    );
  };

  useDayNightCycle(({ time, daynightCycle }) => {
    const colorShift = daynightCycle * 0.5 + 0.5;

    SkyboxMesh.material.uniforms.fTime.value = colorShift;
    LandscapeMesh.material.uniforms.fTime.value = colorShift;

    updateMoon({
      time,
      daynightCycle
    });
  });

  return (
    <group position={position}>
      <primitive object={SkyboxMesh} />

      <mesh ref={moonRef}>
        <planeGeometry args={[24, 24]} />
        <meshBasicMaterial
          map={moonTexture}
          transparent
        />
      </mesh>

      <primitive position={[0.0, 0.0, 0.02]} object={LandscapeMesh} />
    </group>
  );
};

class SkyboxMaterial extends THREE.MeshBasicMaterial {
  uniforms = {
    fTime: { value: 0.0 },
  };

  constructor() {
    super({
      map: new THREE.TextureLoader().load(`${CDN_GATEWAY_BASE}/images/bg-stars-noise.png`, texture => {
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      }),
    });

    this.onBeforeCompile = (shader) => {
      Object.keys(this.uniforms).forEach((key) => {
        shader.uniforms[key] = this.uniforms[key as keyof typeof this.uniforms];
      });

      shader.vertexShader = shader.vertexShader
      .replace(
        '#include <common>',
        `
        #include <common>

        varying vec2 vUv;
        `
      )
      .replace(
        '#include <begin_vertex>',
        `
        #include <begin_vertex>

        vUv = uv;
        `
      );

      shader.fragmentShader = shader.fragmentShader
      .replace(
        '#include <common>',
        `
        #include <common>

        uniform float fTime;

        varying vec2 vUv;
        `
      )
      .replace('#include <map_fragment>', '')
      .replace(
        '#include <dithering_fragment>',
        `
          vec3 vColorSky = mix(
            vec3(81., 130., 247.),
            vec3(6., 6., 6.),
            fTime
          ) / 255.;
          vec3 vColorGround = mix(
            vec3(173., 227., 252.),
            vec3(32., 32., 64.),
            fTime
          ) / 255.;

          gl_FragColor.rgb = mix(
            vColorSky,
            vColorGround,
            (1.0 - vUv.y) * 2.
          );

          vec2 vSampleStars = vUv * vec2(1.0, 60. / 34.);
          vec4 sampleStars = texture2D(map, vSampleStars);

          if (sampleStars.a > 0.0 && sampleStars.r > 0.9172) {
            gl_FragColor.rgb += mix(
              vec3(0.),
              sampleStars.rgb * 0.5,
              fTime
            );
          }
        `
      )
    };
  }
}

class LandscapeMaterial extends THREE.MeshBasicMaterial {
  uniforms = {
    fTime: { value: 0.0 },
  };

  constructor() {
    super({
      map: new THREE.TextureLoader().load(`${CDN_GATEWAY_BASE}/images/bg-png.png`),
    });

    this.onBeforeCompile = (shader) => {
      Object.keys(this.uniforms).forEach((key) => {
        shader.uniforms[key] = this.uniforms[key as keyof typeof this.uniforms];
      });

      shader.vertexShader = shader.vertexShader
      .replace(
        '#include <common>',
        `
        #include <common>

        varying vec2 vUv;
        `
      )
      .replace(
        '#include <begin_vertex>',
        `
        #include <begin_vertex>

        vUv = uv;
        `
      );

      shader.fragmentShader = shader.fragmentShader
      .replace(
        '#include <common>',
        `
        #include <common>

        uniform float fTime;

        varying vec2 vUv;
        `
      )
      .replace('#include <map_fragment>', '')
      .replace(
        '#include <dithering_fragment>',
        `
          vec2 vSampleMap = vUv * vec2(1.0, 60. / 34.);
          vec4 sampleMap = texture2D(map, vSampleMap);

          if (vSampleMap.y < 1.0 && sampleMap.a > 0.5) {
            gl_FragColor.rgb = sampleMap.rgb * mix(
              vec3(255.),
              vec3(64., 64., 164.),
              fTime
            ) / 255.;
          } else {
            discard;
          }
        `
      )
    };
  }
}

const SkyboxMesh = new THREE.Mesh(
  new THREE.PlaneGeometry(34, 60),
  new SkyboxMaterial()
);

const LandscapeMesh = new THREE.Mesh(
  new THREE.PlaneGeometry(34, 60),
  new LandscapeMaterial()
);
