import { useCallback, useEffect, useRef } from 'react';
import { useUserStore } from '../store';

const MAX_UPDATE_INTERVAL = 160000;
const UPDATE_INTERVAL_DELTA = 5000;

export const useHealth = () => {
  const fetchedLastHealing = useUserStore((state) => state.user.fetchedLastHealing);
  const lastHealing = useUserStore((state) => state.user.fetchedLastHealing);
  const health = useUserStore((state) => state.user.health);
  const endurance = useUserStore((state) => state.user.endurance);
  const isZeroHealth = health === 0;
  const isHealthFetched = health !== null;

  const hunger = useUserStore((state) => state.user.hunger);

  const healthUpdateInterval = MAX_UPDATE_INTERVAL - hunger * UPDATE_INTERVAL_DELTA;

  const isMaxHealth = health === 20 + endurance * 3;

  const increaseHealth = useUserStore((state) => state.increaseHealth);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const updateHealth = useCallback(() => {
    if (isMaxHealth) {
      return;
    }

    const healthToIncrease = 1;
    increaseHealth(healthToIncrease);
  }, [increaseHealth, isMaxHealth]);

  const clearCurrentInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  useEffect(() => {
    if (isZeroHealth || !isHealthFetched) {
      clearCurrentInterval();
      return;
    }

    const fetchedLastHealingDate = fetchedLastHealing ? new Date(fetchedLastHealing) : null;

    const remainder = fetchedLastHealingDate
      ? (Date.now() - fetchedLastHealingDate.getTime()) % healthUpdateInterval
      : null;

    const delay = remainder === null ? null : healthUpdateInterval - remainder;

    const startInterval = () => {
      clearCurrentInterval();

      intervalRef.current = setInterval(updateHealth, healthUpdateInterval);
    };

    if (!timeoutRef.current && delay !== null) {
      timeoutRef.current = setTimeout(() => {
        updateHealth();
        startInterval();
      }, delay);
    } else {
      startInterval();
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [updateHealth, fetchedLastHealing, lastHealing, isHealthFetched, isZeroHealth, healthUpdateInterval]);
};
