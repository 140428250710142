import { useEffect, useRef } from "react";
import { useWebSocketContext, WebSocketContext } from "../../WebSocketContext";
import { useGameStore, useUserStore, WebSocketBattleState } from "../../../store";
import { IUserinfo } from "../../../types/interface";
import { MarkerPosition } from "./owlControls/useBattleOwlControls";

type AdditionalUserBattleInfoResponse = {
  username: string;
  avatar: string | null;
  skin: {
    img: string;
  } | null;
  max_hp: number;
  current_hp: number;
};

export const startOwlMatchmaking = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  difficulty?: 'easy' | 'medium' | 'hard'
) => {
  context.sendMessage(JSON.stringify({
    type: 'START_FIGHT',
    user_tgid: userInfo.tg_id,
    fight_type: difficulty ?? 'easy'
  }));
};

export const makeBattleChoice = (
  context: WebSocketContext,
  userInfo: IUserinfo,
  attack: MarkerPosition | null,
  defence: MarkerPosition | null
) => {
  context.sendMessage(JSON.stringify({
    type: 'FIGHT_CHOICE',
    user_tgid: userInfo.tg_id,
    attack,
    defence,
  }));
};

export const useOwlBattleService = () => {
  const { lastMessage } = useWebSocketContext();
  const {
    battleError,
    setBattleState,
    setBattleError,
    setIncomingBattleState,
    setBattleSpoils,
    setBattleUser1,
    setBattleUser2,
  } = useGameStore();
  const { user } = useUserStore();
  const useCurrentBattleAsSourceRef = useRef(true);

  const fetchAdditionalUsersInfo = (source: WebSocketBattleState) => {
    if (user?.current_battle) {
      // NOTE battleUser1 and battleUser2 can be read directly from current_battle
      return;
    }

    if (source.round !== 0) {
      return;
    }

    setBattleUser1(null);
    setBattleUser2(null);

    fetch(`https://api.masonsplay.com/getEnemyInfo/${source.user1.tgid}/`)
      .then(res => res.json())
      .then((data: AdditionalUserBattleInfoResponse) => {
        setBattleUser1({
          username: data.username ?? 'MissingNo',
          maxHp: data.max_hp,
          skin: data.skin ?? null,
          avatar: data.avatar ?? null,
        });
      })
      .catch(() => {});

    fetch(`https://api.masonsplay.com/getEnemyInfo/${source.user2.tgid}/`)
      .then(res => res.json())
      .then((data: AdditionalUserBattleInfoResponse) => {
        setBattleUser2({
          username: data.username ?? 'MissingNo',
          maxHp: data.max_hp,
          skin: data.skin ?? null,
          avatar: data.avatar ?? null,
        });
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (!useCurrentBattleAsSourceRef.current) {
      return;
    }

    setBattleState(user?.current_battle);
    setBattleUser1({
      username: user?.current_battle?.user1.username,
      maxHp: user?.current_battle?.user1.max_hp,
      skin: user?.current_battle?.user1.skin ?? null,
      avatar: user?.current_battle?.user1.avatar ?? null,
    });
    setBattleUser2({
      username: user?.current_battle?.user2.username,
      maxHp: user?.current_battle?.user2.max_hp,
      skin: user?.current_battle?.user2.skin ?? null,
      avatar: user?.current_battle?.user2.avatar ?? null,
    });
  }, [user?.current_battle]);

  useEffect(() => {
    if (!lastMessage) {
      return;
    }

    try {
      const message = JSON.parse(lastMessage.data);

      if (message.error) {
        setBattleState(null);
        setBattleSpoils(null);
        setBattleError(message.error);

        return;
      } else if (battleError) {
        setBattleError(null);
      }

      if ([
        'FIGHT_UPDATE',
        'FIGHT_END',
      ].includes(message.type)) {
        setBattleState(message.state);
        fetchAdditionalUsersInfo(message.state);

        if (message.state.coins) {
          setBattleSpoils({
            exp: message.state.exp_amount,
            coins: message.state.coins,
          });
        }
      }

      if (message.type === 'WILL_START') {
        setIncomingBattleState(message.state);
        fetchAdditionalUsersInfo(message.state);

        setTimeout(() => {
          setBattleState(message.state);
          setIncomingBattleState(null);
        }, 3000);
      }
    } catch {
      console.error('useOwlBattleService', 'failed to parse message', lastMessage);
    }
  }, [lastMessage]);
};
