import { useNavigate, useLocation } from 'react-router-dom';
import { tg_haptic } from '../../utils/telegramapi';
import { useGameStore } from '../../store/';

interface NavProps {
  isBranch: boolean;
  gameMode: any;
  setIsNeedShown: any;
  setGameMode: any;
  userExp: number;
  skillPoints: number;
  isShop?: boolean;
}

export const MainNav = (props: NavProps) => {
  const { isBranch, userExp, skillPoints } = props;
  const navigate = useNavigate();

  const { gameMode, setGameMode, gameStage, animating, setAnimating } = useGameStore((state) => ({
    gameMode: state.gameMode,
    setGameMode: state.setGameMode,
    gameStage: state.gameStage,
    animating: state.animating,
    setAnimating: state.setAnimating,
  }));

  const handleClick = (type: string) => {
    type === 'home' ? navigate('/') : navigate(`/${type}`);
  };

  const renderActionButton = () => {
    if (animating) return <ActionButton icon="nw-icon-locked" label="flying" disabled />;

    if (isBranch) {
      if (gameMode === 'training' || gameMode === 'fighting') {
        return (
          <ActionButton
            icon="nw-icon-branch"
            label="branch"
            onClick={() => {
              setGameMode('normal');
              setAnimating(true);
              tg_haptic.impactOccurred('light');
            }}
          />
        );
      }
      if (gameStage === 1) {
        return (
          <ActionButton
            icon="nw-icon-locked"
            label="train"
          />
        );
      }
      if (gameStage === 2) {
        return (
          <ActionButton
            icon="nw-icon-fight"
            label="train"
            onClick={() => {
              setGameMode('training');
              setAnimating(true);
              tg_haptic.impactOccurred('light');
            }}
          />
        );
      }
      return (
        <ActionButton
          icon="nw-icon-fight"
          label="fight"
          onClick={() => {
            setGameMode('fighting');
            setAnimating(true);
            tg_haptic.impactOccurred('light');
          }}
        />
      );
    }
    return <ActionButton icon="nw-icon-branch" label="branch" onClick={() => handleClick('home')} />;
  };

  const navClass = `fixed bottom-8 left-1/2 -translate-x-1/2 z-10 flex w-11/12 items-center justify-between ${
    isBranch ? 'bg-[#D9D9D960]' : 'bg-white/[.08]'
  } backdrop-blur rounded-xl py-1`;

  return (
    <>
    {userExp >= 2000 ?
      <div className={navClass}>
        <NavItem path="/frens" icon="nw-icon-frens" label="frens" onClick={() => handleClick('frens')}/>
        <NavItem path="/earn" icon="nw-icon-gift" label="earn" onClick={() => handleClick('earn')}/>
        {renderActionButton()}
        <NavItem path="/store" icon="nw-icon-cart" label="shop" onClick={() => handleClick('store')}/>
        <NavItem path="/profile" icon={skillPoints > 0 ? "nw-icon-plus-filled" : "nw-icon-eyes"} label={skillPoints > 0 ? "new lvl" : "my owl"} onClick={() => handleClick('profile')}
                 showPing={skillPoints > 0}/>
      </div>
      :
      <div className={navClass}>
        <NavItem path="/frens" icon="nw-icon-locked" label="frens"/>
        <NavItem path="/earn" icon="nw-icon-locked" label="earn"/>
        {renderActionButton()}
        <NavItem path="/store" icon="nw-icon-locked" label="shop"/>
        <NavItem path="/profile" icon={skillPoints > 0 ? "nw-icon-plus-filled" : "nw-icon-eyes"} label={skillPoints > 0 ? "new lvl" : "my owl"} onClick={() => handleClick('profile')}
                 showPing={skillPoints > 0}/>
      </div>
    }
    </>
  );
};

interface NavItemProps {
  path?: string;
  icon: string;
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  showPing?: boolean;
}

const NavItem = ({ path, icon, label, onClick, disabled = false, showPing = false }: NavItemProps) => {
  const location = useLocation();
  const isActive = path && location.pathname === path;

  return (
    <span
      className={`flex w-1/5 ${disabled ? 'text-white/[60%]' : 'text-white'}`}
      onClick={onClick}
    >
      <span className={`flex flex-col items-center w-11/12 py-2 rounded-xl ${isActive ? 'bg-white/[.05]' : 'bg-transparent'}`}>
      <span className={`${icon} text-[1.7rem] ${showPing && "text-[#F9C24F]"}`}></span>
      <p className={`text-xs font-normal ${showPing && "text-[#F9C24F]" }`}>{label}</p>
      {showPing && (
        <span className="absolute flex h-3 w-3 right-4">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75" />
          <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500" />
        </span>
      )}
      </span>
    </span>
  );

};

const ActionButton = ({  icon, label, onClick, disabled = false }: NavItemProps) => (
  <NavItem icon={icon} label={label} onClick={onClick} disabled={disabled} />
);
