import { useEffect, useMemo, useRef, useState } from 'react';
import { handleAnimation } from '../../../../utils';
import { BatchedRenderer } from 'three.quarks';
import { AnimationAction, Box3, Object3D, Vector3 } from 'three';
import { Html } from '@react-three/drei';
import styled from 'styled-components';
import { useGameStore, useUserStore } from '../../../../store';
import { ParticleSystemUtil } from '../../particles/ParticleSystemUtil';
import { useThree } from '@react-three/fiber';
import { OWL_ANIMATION_TIMELINE } from '../../../../constants';
import { useWebSocketContext } from '../../../WebSocketContext';
import { makeBattleChoice } from '../useOwlBattleService';
import { AudioSystemUtil } from '../../particles/AudioSystemUtil';
import { BattleLottiePlayer } from '../../../LottiePlayer/BattleLottiePlayer';

type OwlBattleStance = 'idle' | 'attack' | 'damaged';
export type MarkerPosition = 'top' | 'center' | 'bottom';

const getActionByStance = (
  stance: OwlBattleStance,
  target: MarkerPosition | null,
  isForeignOwl?: boolean,
): keyof typeof OWL_ANIMATION_TIMELINE => {
  if (stance === 'attack') {
    return {
      top: 'attack_head',
      center: 'attack_body',
      bottom: 'attack_stomach',
    }[target ?? 'center'];
  } else if (stance === 'damaged') {
    return {
      top: 'damage_head',
      center: 'damage_body',
      bottom: 'damage_stomach',
    }[target ?? 'center'];
  }

  return isForeignOwl ? 'idle_new' : 'idle';
};

export const getModelSlotByMarkerPosition = (marker: MarkerPosition): string => {
  return {
    top: 'Owl_Head_low',
    center: 'Owl_Body_Wings_low',
    bottom: 'Owl_Legs_low',
  }[marker];
};

export const useBattleOwlControls = (
  enabled: boolean = false,
  {
    owl,
    actions,
    isForeignOwl,
    batchedRenderer,
    particleEffects,
  }: {
    owl: Object3D | null;
    actions: Record<string, AnimationAction | null> | null;
    isForeignOwl?: boolean;
    batchedRenderer: BatchedRenderer;
    particleEffects: Record<string, Object3D | undefined>;
  },
) => {
  const { scene: mainScene } = useThree();
  const { battleState } = useGameStore();
  const isInBattle = !!battleState?.uuid;
  const userInfo = battleState?.user1;
  const enemyInfo = battleState?.user2;
  const slotPositions = useRef<Record<string, Vector3>>({});
  const user = useUserStore((state) => state.user);
  const webSocketContext = useWebSocketContext();
  const [attackTarget, defenceTarget] = useMemo(() => {
    if (!isInBattle || !userInfo || !enemyInfo) {
      return [null, null];
    }

    return [userInfo.attack, userInfo.defence];
  }, [isInBattle, userInfo, enemyInfo]);
  const [displayMarkers, setDisplayMarkers] = useState(false);

  useEffect(() => {
    if (!enabled || !actions || !owl) {
      return;
    }

    handleAnimation({
      target: owl,
      model: 'owl',
      action: actions['OwlGroup|Take 001|BaseLayer'],
      animationName: getActionByStance('idle', null, isForeignOwl),
      isLoop: true,
    });
  }, [enabled, actions, owl]);

  useEffect(() => {
    if (!isInBattle || !battleState) {
      return;
    }

    setDisplayMarkers(true);
  }, [battleState?.round, isInBattle]);

  const markers = useMemo(() => {
    if (!enabled || !owl || !isInBattle) {
      return null;
    }

    const animationUrl = isForeignOwl ? '/assets/lottie/attack.json' : '/assets/lottie/def.json';

    const slotMeshes = {
      top: owl.getObjectByName(getModelSlotByMarkerPosition('top')),
      center: owl.getObjectByName(getModelSlotByMarkerPosition('center')),
      bottom: owl.getObjectByName(getModelSlotByMarkerPosition('bottom')),
    };
    const box3 = new Box3();
    const center = new Vector3();

    for (const [slot, mesh] of Object.entries(slotMeshes)) {
      if (!mesh) {
        continue;
      }

      box3.setFromObject(mesh);
      box3.getCenter(center);

      slotPositions.current[slot] = center.clone();

      owl.worldToLocal(slotPositions.current[slot]);
    }

    const slotPositionsUnref = slotPositions.current;

    return (
      <>
        {Object.entries(slotPositionsUnref).map(([slot, position]) => (
          <Html key={slot} position={position} style={{ pointerEvents: 'none' }}>
            <BattleMarker
              $active={(isForeignOwl ? attackTarget : defenceTarget) === slot}
              $hidden={!displayMarkers}
              onPointerDown={() => {
                if (!displayMarkers || !isInBattle || !userInfo) {
                  return;
                }

                const updatedOptimisticState = structuredClone(battleState)!;

                if (isForeignOwl) {
                  updatedOptimisticState.user1.attack = slot as MarkerPosition;
                } else {
                  updatedOptimisticState.user1.defence = slot as MarkerPosition;
                }

                makeBattleChoice(
                  webSocketContext,
                  user,
                  updatedOptimisticState.user1.attack,
                  updatedOptimisticState.user1.defence,
                );

                useGameStore.setState({
                  battleState: updatedOptimisticState
                });
              }}
            >
              <BattleLottiePlayer
                preloaderStyle={'round'}
                lottieUrl={animationUrl}
                isLoop={(isForeignOwl ? attackTarget : defenceTarget) === slot}
              />
            </BattleMarker>
          </Html>
        ))}
      </>
    );
  }, [isInBattle, isForeignOwl, enabled, owl, attackTarget, defenceTarget, displayMarkers, battleState?.round]);

  useEffect(() => {
    if (!enabled || !owl || !isInBattle || !userInfo || !enemyInfo) {
      return;
    }

    if (battleState.round === 0) {
      return;
    }

    setDisplayMarkers(false);

    const dataId = isForeignOwl ? 'user2' : 'user1';

    let attackedMarker: MarkerPosition;
    let damagedMarker: MarkerPosition;

    if (isForeignOwl) {
      attackedMarker = enemyInfo.attack ?? 'center';
      damagedMarker = userInfo.attack ?? 'center';
    } else {
      attackedMarker = userInfo.attack ?? 'center';
      damagedMarker = enemyInfo.attack ?? 'center';
    }

    const renderParticles = () => {
      setTimeout(() => {
        const worldPosition = owl.getWorldPosition(new Vector3());

        if (slotPositions.current.top) {
          worldPosition.add(slotPositions.current[attackTarget ?? 'center']);
        }

        ParticleSystemUtil.prepareEffect('Bang', particleEffects.bang, worldPosition);
        ParticleSystemUtil.playEffect('Bang', mainScene, particleEffects.bang, batchedRenderer);
      }, 300);
    };

    const playMarkerAnimation = (stance: 'attack' | 'damaged', marker: MarkerPosition, onComplete?: () => void) => {
      if (!actions) {
        return;
      }

      const activeAction = getActionByStance(stance, marker, isForeignOwl);

      if (stance === 'attack') {
        // @ts-ignore
        AudioSystemUtil.play(`sfx-hit-${Math.floor(Math.random() * 4.0) + 1.0}`, false);
      }

      handleAnimation({
        target: owl,
        model: 'owl',
        action: actions['OwlGroup|Take 001|BaseLayer'],
        animationName: activeAction,
        isLoop: false,
        onComplete,
      });
    };

    const returnToIdle = () => {
      if (!actions) {
        return;
      }

      const updatedOptimisticState = structuredClone(useGameStore.getState().battleState)!;
      updatedOptimisticState[dataId].attack = updatedOptimisticState[dataId].defence = null;
      useGameStore.setState({
        battleState: updatedOptimisticState
      });

      handleAnimation({
        target: owl,
        model: 'owl',
        action: actions['OwlGroup|Take 001|BaseLayer'],
        animationName: getActionByStance('idle', null, isForeignOwl),
        isLoop: true,
      });

      setDisplayMarkers(true);
    };

    if (isForeignOwl) {
      playMarkerAnimation('damaged', damagedMarker);
      renderParticles();
    } else {
      playMarkerAnimation('attack', attackedMarker);
    }

    setTimeout(() => {
      if (isForeignOwl) {
        playMarkerAnimation('attack', attackedMarker, returnToIdle);
      } else {
        playMarkerAnimation('damaged', damagedMarker, returnToIdle);
        renderParticles();
      }
    }, 1500);
  }, [enabled, owl, battleState?.round]);

  const floatingLabels = useMemo(() => {
    if (!enabled || !owl || !isInBattle || !battleState) {
      return null;
    }

    const dataId = isForeignOwl ? enemyInfo : userInfo;
    const damage = dataId?.damage ?? 0.0;

    // NOTE Slot positions are precalculated for markers, so we can reuse them here to get owl position
    const slotPositionsUnref = slotPositions.current;
    const owlCenter = slotPositionsUnref.center;

    if (!owlCenter || !damage) {
      return null;
    }

    return (
      <>
        <Html key={`${battleState.round}-${!!isForeignOwl}`} position={owlCenter} style={{ pointerEvents: 'none' }}>
          <FloatingLabel $delayMs={!isForeignOwl ? 1700 : 300}>
            <span className="font-semibold text-xl text-center text-white">
              <span>-{damage}HP</span>
              <span>{damage > 1 ? '💥' : '🛡️'}</span>
            </span>
          </FloatingLabel>
        </Html>
      </>
    );
  }, [enabled, owl, battleState?.round, isForeignOwl]);

  return enabled
    ? {
        children: (
          <>
            {markers}
            {floatingLabels}
          </>
        ),
      }
    : {};
};

const FloatingLabel = styled.div<{
  $delayMs: number;
}>`
  @keyframes FloatingLabelPopIn {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }

    5% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(2);
    }

    10% {
      transform: translate(-50%, -60%) scale(1);
    }

    90% {
      transform: translate(-50%, -330%) scale(1);
    }

    100% {
      opacity: 0;
      transform: translate(-50%, -340%) scale(1);
    }
  }

  display: inline-flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  animation: FloatingLabelPopIn 3s ease ${({ $delayMs }) => $delayMs}ms alternate 1 both;
`;

const BattleMarker = styled.div<{
  $active: boolean;
  $hidden?: boolean;
}>`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(${({ $hidden }) => ($hidden ? 0.0 : 1.0)});
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  pointer-events: all;
  transition: opacity 0.3s ease, transform 0.3s ease;
`;
