import { useQuery } from './useQuery';
import { ResponseType } from 'axios';
import { QueryObserverOptions } from '@tanstack/react-query';

export const useGetAchievement = (
  url: string,
  responseType: ResponseType,
  options: Partial<QueryObserverOptions<any, any, any>>,
) => {
  const { data, ...rest } = useQuery<string>({
    url,
    method: 'get',
    responseType,
    options,
  });

  return { data, ...rest };
};
