import lvlBg from '../../assets/icon-level-bg.svg';
import { useState } from 'react';
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../../store";

type Props = {
  avatar?: string;
  name?: string;
  experience?: number;
  rank?: number;
  level?: number;
  isLoading?: boolean;
  tgId: number;
  showArrow: boolean;
};

export const Leader = ({ avatar, name, experience, rank, level, isLoading, tgId, showArrow }: Props) => {
  const navigate = useNavigate();
  const userInfo = useUserStore((state) => state.user);
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <div className="grid grid-cols-2 w-full" onClick={()=> {if(userInfo.tg_id !== tgId) navigate(`/freninfo/${tgId}`)}}>
      <div className="relative flex space-x-2.5">
        {avatar && (
          <img src={avatar} alt="avatar" className="w-9 h-9 rounded-full" onLoad={() => setIsImageLoading(false)} />
        )}
        {(isLoading || isImageLoading) && <div className="w-9 h-9 rounded-full bg-slate-700 animate-pulse" />}

        <div className="flex flex-col justify-between">
          <div className="flex space-x-2 items-center mr-1">
            {!isLoading && <div className="text-white font-bold text-sm">{`${name ? name : 'id' + tgId}`}</div>}
            {isLoading && <div className="h-3 w-14 bg-slate-700 animate-pulse" />}
            {!isLoading && (
              <div
                className="w-[22px] h-[20px] pt-[1px]"
                style={{
                  backgroundImage: `url(${lvlBg})`,
                  backgroundSize: 'cover',
                }}
              >
                <div className="w-full text-center text-white font-bold text-[11px] leading-[20px]">{level}</div>
              </div>
            )}

            {isLoading && <div className="w-5 h-5 bg-slate-700 rounded-full animate-pulse" />}
          </div>
          {!isLoading && <div className="text-[#E3EAF699] text-xs">{experience} XP</div>}
          {isLoading && <div className="h-3 w-10 bg-slate-700 animate-pulse" />}
        </div>
        { !isLoading && showArrow &&
        <span className={"text-white/[.2] nw-icon-arrow -rotate-90"}></span>
        }
      </div>

      <div className="flex space-x-2 items-center justify-self-end">
        {rank === 1 && <div>👑</div>}
        <div className="text-white font-bold">{rank}</div>
        {isLoading && <div className="h-3 w-5 bg-slate-700 animate-pulse" />}
      </div>
    </div>
  );
};
