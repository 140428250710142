import * as THREE from 'three';
import styled from 'styled-components';
import { SceneView, Views } from '../views';
import { PropsWithChildren, useEffect, useState } from 'react';
import { imperativeLoad, imperativePrecompile } from '../hooks/useGLTF';
import { CDN_GATEWAY_BASE } from '../../../config';
import { useTokenStore } from '../../../store/useTokenStore';

THREE.Cache.enabled = true;

/**
 * NOTE Rounds percentage values to 10s, prevents unnatural values like 33%, 66% etc.
 */
const renderSimplePercentage = (value: number): string => {
  return `${Math.round(Math.trunc(value) / 10.0) * 10.0}`;
};

export const AssetsPreloader = ({
  children,
  viewId,
}: PropsWithChildren<{
  viewId: SceneView;
}>) => {
  const [progress, setProgress] = useState(0);

  const [connected, setConnected] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const { preloadAssets } = Views[viewId];

  const verifyConnection = () => {
    const token = useTokenStore.getState();

    if (token) {
      setConnected(true);

      return;
    }

    setConnected(false);

    const verifyInterval = setInterval(() => {
      const token = useTokenStore.getState();

      if (token) {
        setConnected(true);

        clearInterval(verifyInterval);
      }
    }, 1500);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (progress === 100 && connected) {
      const delay = (progress < 100 || !connected ? 0 : 1) * 1000;

      timeout = setTimeout(() => {
        setIsVisible(false);
      }, 1000 + delay);
    }

    return () => clearTimeout(timeout);
  }, [connected, progress]);

  useEffect(() => {
    const totalAssets = preloadAssets.length ?? 0.0;
    let currentAssets = 0;

    setProgress(0);

    Promise.all(
      preloadAssets.map(async (url) => {
        if (url.endsWith('.glb') || url.endsWith('.gltf')) {
          const gltf = await imperativeLoad(url);

          await imperativePrecompile(gltf.scene);

          currentAssets++;

          setProgress((currentAssets / totalAssets) * 100.0);
        } else if (url.endsWith('.json')) {
          const response = await fetch(url);

          if (response.ok) {
            const json = await response.text();

            THREE.Cache.add(url, json);
          }

          currentAssets++;

          setProgress((currentAssets / totalAssets) * 100.0);
        }
      }),
    ).then(() => {
      verifyConnection();
    });
  }, [preloadAssets]);

  return (
    <Container>
      <Overlay $progress={progress} $connected={connected} $visible={isVisible}>
        <LoadingBar $progress={progress}>
          <Label>{progress < 100 ? `${renderSimplePercentage(progress)}%` : 'connecting'}</Label>
        </LoadingBar>
      </Overlay>
      <Content>{children}</Content>
    </Container>
  );
};

const Label = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  color: #ffffff;
  font-size: 10px;
  transform: translate(-0%, 100%);
  z-index: 2;
`;

const LoadingBar = styled.div<{
  $progress: number;
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 8px;
  border: solid 1px #ffffff;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  background: #00000044;
  z-index: 2;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ $progress }) => $progress}%;
    height: 100%;
    z-index: 1;
    background: #ffffff;
    content: '';
    transition: width 0.6s ease;
  }
`;

const Overlay = styled.div<{
  $progress: number;
  $connected: boolean;
  $visible: boolean;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  background: linear-gradient(180deg, #335282 0%, #21331a 100%);
  z-index: 9999;

  ${({ $progress, $connected, $visible }) => `
    display: ${$visible ? 'block' : 'none'};
    opacity: ${$progress < 100 || !$connected ? 1 : 0};
    transition: opacity ${$progress < 100 || !$connected ? 0 : 1}s 1s ease;
  `}
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-image: url(${CDN_GATEWAY_BASE}/images/bg-top.webp);
    background-size: cover;
    opacity: 0.5;
    z-index: 1;
    filter: blur(24px);
    content: '';
  }
`;

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
`;
