import { REACT_APP_BACKEND_URL } from '../config';
import axios, { AxiosRequestConfig, ResponseType } from 'axios';
import { useTokenStore } from '../store/useTokenStore';
import { QueryObserverOptions, useQuery as useReactQuery } from '@tanstack/react-query';

type Params = {
  url: string;
  method: AxiosRequestConfig['method'];
  params?: AxiosRequestConfig['params'];
  data?: AxiosRequestConfig['data'];
  responseType?: ResponseType;
  options?: Partial<QueryObserverOptions<any, any, any>>;
};

const queryFn = async <T,>({ url, method, data, params, token, responseType }: Params & { token: string | null }) => {
  const response = await axios<T>({
    method,
    url: responseType === 'blob' ? url : `${REACT_APP_BACKEND_URL}/${url}`,
    params,
    data,
    headers: responseType !== 'blob' ? { Authorization: `Bearer ${token}` } : undefined,
    responseType: responseType ?? undefined,
  });

  return response.data;
};

export const useQuery = <T,>({ url, method, data, params, responseType, options }: Params) => {
  const token = useTokenStore((state) => state.accessToken);

  const { data: queryData, ...rest } = useReactQuery<T>({
    queryKey: [url, method, ...(params ? [params] : []), ...(data ? [data] : [])],
    queryFn: () =>
      queryFn({
        url,
        method,
        data,
        params,
        token,
        responseType,
      }),
    enabled: token !== null,
    select: (data: any) => {
      return responseType === 'blob' ? URL.createObjectURL(data) : data;
    },
    ...options,
  });

  return {
    data: queryData,
    ...rest,
  };
};
