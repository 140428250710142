import { tg_haptic } from '../../utils/telegramapi';
import { useUserStore } from '../../store';
import React from "react";
interface SquadProps {
  squadInfo: any | null;
}

export const SquadWidget = (props: SquadProps) => {
  const { squadInfo } = props;
  const setIsJoiningSquad = useUserStore((state) => state.setIsJoiningSquad);

  const handleJoin = () => {
    setIsJoiningSquad(true);

    const newWindow = window.open('https://t.me/dev_not_gamebot/squads', '_blank');
    if (newWindow) {
      newWindow.focus();
    }
  };

  return squadInfo ? (
    <div
      onClick={() => {
        tg_haptic.impactOccurred('light');
        handleJoin();
      }}
      className="relative flex justify-start items-center pointer-events-auto"
    >
      <img alt="img" src={`${squadInfo.logo}`} className="w-6 rounded-full" />
      <div className="ml-1 flex items-center">
        <span className="flex items-center text-xs font-semibold text-white w-auto">
          <span className="inline-flex max-w-40">{squadInfo.name}</span>
          <span className={'inline-flex ml-1 text-white/[.2] nw-icon-arrow -rotate-90'}></span>
        </span>
      </div>
    </div>
  ) : (
    <div
      onClick={() => {
        tg_haptic.impactOccurred('light');
        handleJoin();
      }}
      className="relative flex justify-start items-center pointer-events-auto"
    >
      <div className="flex items-center">
        <span className="flex items-center text-xs font-semibold text-white"><span className={`nw-icon-notcoin text-lg mr-1 -ml-1`}></span>Join Squad
          <span className={'inline-flex ml-1 text-white/[.2] nw-icon-arrow -rotate-90'}></span>
        </span>
      </div>

    </div>
  );
};
