export const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_TEST == 'true' ? 'https://api.masonsplay.com' : 'https://api.notwise.co';
export const REACT_APP_WEBSOCKET_URL =
  process.env.REACT_APP_TEST == 'true' ? 'wss://api.masonsplay.com' : 'wss://api.notwise.co';
// export const REACT_APP_WEBSOCKET_URL = 'ws://localhost:8080/';
export const CDN_GATEWAY_BASE = '/assets';

export const STAGES: { [key: number]: number } = {
  1: 0,
  2: 2000,
  3: 6000
}
export const LEVELS: { [key: number]: number } = {
  0: 0,
  1: 2000,
  2: 6000,
  3: 12000,
  4: 20000,
  5: 32000,
  6: 48000,
  7: 60000,
  8: 75000,
  9: 88000,
  10: 100000,
  11: 120000,
  12: 140000,
  13: 160000,
  14: 180000,
  15: 250000,
  16: 300000,
  17: 350000,
  18: 400000,
  19: 500000,
  20: 800000,
  21: 1000000
};

export const MAX_ENERGY_BY_LEVEL: { [key: number]: number } = {
  0: 500,
  1: 600,
  2: 700,
  3: 800,
  4: 900,
  5: 1000,
  6: 1100,
  7: 1200,
  8: 1300,
  9: 1400,
  10: 1500,
  11: 1600,
  12: 1700,
  13: 1800,
  14: 1900,
  15: 2000,
  16: 2100,
  17: 2200,
  18: 2300,
  19: 2400,
  20: 2500,
  21: 2600
};
