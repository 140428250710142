import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import IconLeft from '../../assets/icon-left.svg';
import { useNavigateByExp } from '../../hooks';
import { useUserStore } from '../../store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px 16px;
  width: 100%;
  height: 100%;
`;

export const SquadList = () => {
  const navigate = useNavigate();
  const navigateByExp = useNavigateByExp();
  const totalExp = useUserStore((state) => state.user.totalExp);
  const tg = window.Telegram.WebApp;
  tg.BackButton.onClick(() => {
    tg.BackButton.hide();
    navigateByExp(totalExp);
  });

  return (
    <Wrapper className="bg-gradient-to-t from-[#e8b20934] via-[#00000036] to-[#000]  backdrop-blur-xl z-2">
      <div className="w-full">
        {process.env.REACT_APP_ENV_DEV && (
          <div className="flex items-center gap-2 mb-1 w-full">
            <button className="w-4 h-full mt-2 pointer-events-auto" onClick={() => navigate(-1)}>
              <img src={IconLeft} alt="IconLeft" />
            </button>
          </div>
        )}
        <div className="flex flex-col justify-center items-center mt-6 mb-2 gap-1">
          <img alt="img" src="https://notwise.b-cdn.net/icons/nyansapo-big.png" width={100} height="auto" />
        </div>
        <div className="flex flex-col items-center justify-center w-full mb-1">
          <h6 className="text-2xl font-bold text-white">
            <span className="text-[#ffe000] empires">Earn</span> more coins
          </h6>
        </div>
        <div className="flex flex-col mt-6">
          <div className="relative flex justify-between items-center rounded-xl bg-[#D9D9D930] w-full px-4 py-4 mb-5">
            <div className="flex justify-start items-center">
              <img
                alt="img"
                src="https://notwise.b-cdn.net/images/goods/food/bee.png"
                className="icon bg-[#D9D9D930] w-2/12 h-auto rounded-xl"
              />
              <div className="flex flex-col ml-3">
                <div className="text-white/[.8] font-medium text-sm">Invite Bonus</div>
                <div className="text-white font-medium text-sm">
                  <b>
                    up to{' '}
                    <img
                      alt="img"
                      className="inline-flex"
                      src="https://notwise.b-cdn.net/icons/coin-icon.png"
                      width={12}
                      height={12}
                    />{' '}
                    100,000
                  </b>{' '}
                  per fren
                </div>
              </div>
            </div>
            <img
              className="opacity-50 -rotate-90"
              src="https://notwise.b-cdn.net/icons/arrow.svg"
              width={20}
              height={20}
              alt="icon"
            />
          </div>
        </div>
        <h3 className="text-white font-bold">Onboarding</h3>
        <div className="flex flex-col mt-3">
          <div className="relative flex justify-between items-center rounded-xl bg-[#D9D9D930] w-full px-4 py-4 mb-3">
            <div className="flex justify-start items-center">
              <img
                alt="img"
                src="https://notwise.b-cdn.net/images/goods/food/bee.png"
                className="icon bg-[#D9D9D930] w-2/12 h-auto rounded-xl"
              />
              <div className="flex flex-col ml-3">
                <div className="text-white/[.8] font-medium text-sm">Invite Bonus</div>
                <div className="text-white font-medium text-sm">
                  <b>
                    up to{' '}
                    <img
                      alt="img"
                      className="inline-flex"
                      src="https://notwise.b-cdn.net/icons/coin-icon.png"
                      width={12}
                      height={12}
                    />{' '}
                    100,000
                  </b>{' '}
                  per fren
                </div>
              </div>
            </div>
            <img
              className="opacity-50 -rotate-90"
              src="https://notwise.b-cdn.net/icons/arrow.svg"
              width={20}
              height={20}
              alt="icon"
            />
          </div>
          <div className="relative flex justify-between items-center rounded-xl bg-[#D9D9D930] w-full px-4 py-4 mb-5">
            <div className="flex justify-start items-center">
              <img
                alt="img"
                src="https://notwise.b-cdn.net/images/goods/food/bee.png"
                className="icon bg-[#D9D9D930] w-2/12 h-auto rounded-xl"
              />
              <div className="flex flex-col ml-3">
                <div className="text-white/[.8] font-medium text-sm">Invite Bonus</div>
                <div className="text-white font-medium text-sm">
                  <b>
                    up to{' '}
                    <img
                      alt="img"
                      className="inline-flex"
                      src="https://notwise.b-cdn.net/icons/coin-icon.png"
                      width={12}
                      height={12}
                    />{' '}
                    100,000
                  </b>{' '}
                  per fren
                </div>
              </div>
            </div>
            <img
              className="opacity-50 -rotate-90"
              src="https://notwise.b-cdn.net/icons/arrow.svg"
              width={20}
              height={20}
              alt="icon"
            />
          </div>
        </div>
        {/* <div className="max-h-64 overflow-y-auto scroll-mr-6">
          {
            FRENS_ARRAY.map((item, index) => (
              <div className="grid grid-cols-12 items-center gap-2 mb-2" key={index}>
                <div className="col-span-1 text-[10px] font-bold text-white">
                  <Link to={`/squad/${index+1}`} >#{index+1}</Link>
                </div>
                <div className="col-span-5">
                  <div className="flex items-center gap-2">
                    <div className="flex justify-center items-center w-3 h-3 rounded-full bg-[#D9D9D9]">
                    </div>
                    <h6 className="text-xs font-normal text-white opacity-60">{item.username}</h6>
                  </div>
                </div>
                <div className="col-span-3">
                  <div className="flex items-center gap-1 text-xs font-bold text-white">
                    <div className="flex justify-center items-center w-2 h-2 rounded-full bg-[#FFB800]">
                      <div className="coin__front rounded-full w-1 h-1 bg-[#FF9900]"></div>
                    </div>
                    {item.earn}
                  </div>
                </div>
                <div className="col-span-3">
                  <button className="flex justify-center items-center gap-1 w-full text-[8px] text-white font-bold rounded-md bg-[#D9D9D950] py-1">
                    <img alt="img" src={Branch} alt="branch"/>branch
                  </button>
                </div>
              </div>
            ))
          }
        </div>
        */}
      </div>
    </Wrapper>
  );
};
