import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { REACT_APP_WEBSOCKET_URL } from '../../config';
import { useTokenStore } from '../../store/useTokenStore';

export type WebSocketContext = {
  sendMessage: (message: string) => void;
  lastMessage: MessageEvent<any> | null;
  readyState: ReadyState;
};

const WebSocketContextImpl = createContext<WebSocketContext | undefined>(undefined);

export const useWebSocketContext = (): WebSocketContext => {
  const context = useContext(WebSocketContextImpl);

  if (!context) {
    throw new Error('useWebSocketContext must be used within a WebSocketProvider');
  }

  return context;
};

export const WebSocketProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [socketUrl, setSocketUrl] = useState<string | null>(null);

  const token = useTokenStore((state) => state.accessToken);

  useEffect(() => {
    if (token) {
      // Формируем URL с параметром JWT
      const wsUrl = `${REACT_APP_WEBSOCKET_URL}/ws?token=${token}`;
      setSocketUrl(wsUrl);
    }
  }, [token]);

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl || null, {
    shouldReconnect: (closeEvent) => true, // Автоподключение
    onOpen: () => {
      console.log('WebSocket connection established');
    },
  });

  const value = {
    sendMessage,
    lastMessage,
    readyState,
  };

  return <WebSocketContextImpl.Provider value={value}>{children}</WebSocketContextImpl.Provider>;
};
