import { useCallback, useEffect, useRef, useState } from 'react';
import { useGameStore, useUserStore } from '../../../../store';
import { detectSwipeModelIntersection, handleAnimation } from '../../../../utils';
import { ParticleSystemUtil } from '../../particles/ParticleSystemUtil';
import { Coords } from '../../components';
import { AnimationAction, Object3D } from 'three';
import { useThree } from '@react-three/fiber';
import { useUserAction } from '../../../../hooks';
import { BatchedRenderer, ConstantValue, ParticleEmitter, ParticleSystem } from 'three.quarks';
import {tg_haptic} from "../../../../utils/telegramapi";
import { AudioSystemUtil } from '../../particles/AudioSystemUtil';

const TOP_MODEL_INTERSECTION_OFFSET = -1;
const BOTTOM_MODEL_INTERSECTION_OFFSET = -0.2;
const LEFT_MODEL_INTERSECTION_OFFSET = -0.1;
const RIGHT_MODEL_INTERSECTION_OFFSET = 0.1;

export const useIdleOwlControls = (
  enabled: boolean = false,
  {
    owl,
    actions,
    batchedRenderer,
    particleEffects,
    colaUsed,
  }: {
    owl: Object3D | null;
    actions: Record<string, AnimationAction | null> | null;
    batchedRenderer: BatchedRenderer;
    particleEffects: Record<string, Object3D | undefined>;
    colaUsed: boolean;
  },
) => {
  const [intersectionStart, setIntersectionStart] = useState(false);
  const [intersectionEnd, setIntersectionEnd] = useState(false);
  const { scene: mainScene, camera } = useThree();
  const { handleClickAction, handleSwipeAction } = useUserAction('owl');
  const { intermediate } = useUserStore();
  const { currentPointerCoords, setCurrentPointerCoords } = useGameStore((state) => ({
    currentPointerCoords: state.currentPointerCoords,
    setCurrentPointerCoords: state.setCurrentPointerCoords,
  }));
  const startIntersectionPoint = useRef<Coords>({ x: 0, y: 0 });

  const happiness = useUserStore((state) => state.user.happiness);

  const isMaxHappiness = happiness === 20;

  const onClick = (event: MouseEvent) => {
    if (!actions || !owl || !particleEffects.questionsMark) {
      return;
    }

    event.stopPropagation();

    handleClickAction(event);

    if (actions['OwlGroup|Take 001|BaseLayer']) {
      handleAnimation({
        target: owl,
        model: 'owl',
        action: actions['OwlGroup|Take 001|BaseLayer'],
        animationName: 'look_with_interest_v2',
        isLoop: false,
        onComplete: () => {
          handleAnimation({
            target: owl,
            model: 'owl',
            action: actions['OwlGroup|Take 001|BaseLayer'],
            animationName: 'idle',
            isLoop: true,
          });
        },
      });
    }
    tg_haptic.impactOccurred('light');
    ParticleSystemUtil.playEffect('QuestionsMark', mainScene, particleEffects.questionsMark, batchedRenderer);

    // @ts-ignore
    AudioSystemUtil.play(`sfx-owl-happy-${Math.floor(Math.random() * 3.0) + 1.0}`, false, 0.5, 0.5);
  };

  const onSwipe = useCallback(
    (point: Coords, model: Object3D) => {
      if (!actions || !owl || isMaxHappiness) {
        return;
      }

      const isIntersection = detectSwipeModelIntersection({
        model,
        point,
        camera,
        offset: {
          top: TOP_MODEL_INTERSECTION_OFFSET,
          bottom: BOTTOM_MODEL_INTERSECTION_OFFSET,
          left: LEFT_MODEL_INTERSECTION_OFFSET,
          right: RIGHT_MODEL_INTERSECTION_OFFSET,
        },
      });

      if (isIntersection && !intersectionStart) {
        setIntersectionStart(true);
        startIntersectionPoint.current = point;
      }

      if (intersectionStart && !isIntersection && !intersectionEnd) {
        setIntersectionEnd(true);
      }

      if (intersectionEnd) {
        setIntersectionStart(false);
        setIntersectionEnd(false);
        setCurrentPointerCoords(null);

        handleSwipeAction(mainScene, particleEffects.heartPoof, batchedRenderer);

        // @ts-ignore
        AudioSystemUtil.play('sfx-owl-happy-4', false, 0.5, 0.5);

        handleAnimation({
          target: owl,
          model: 'owl',
          action: actions['OwlGroup|Take 001|BaseLayer'],
          animationName: 'satisfied_v1',
          isLoop: false,
          onComplete: () => {
            handleAnimation({
              target: owl,
              model: 'owl',
              action: actions['OwlGroup|Take 001|BaseLayer'],
              animationName: 'idle',
              isLoop: true,
            });
          },
        });
      }
    },
    [
      actions,
      camera,
      handleSwipeAction,
      intersectionEnd,
      intersectionStart,
      mainScene,
      setCurrentPointerCoords,
      particleEffects.heartPoof,
    ],
  );

  useEffect(() => {
    if (!enabled || !owl || !currentPointerCoords) {
      return;
    }

    onSwipe(currentPointerCoords, owl);
  }, [enabled, owl, currentPointerCoords, onSwipe]);

  useEffect(() => {
    if (!enabled || !actions) {
      return;
    }

    handleAnimation({
      target: owl,
      model: 'owl',
      action: actions['OwlGroup|Take 001|BaseLayer'],
      animationName: 'idle',
      isLoop: true,
    });
  }, [actions, owl]);

  useEffect(() => {
    if (!particleEffects.levelUp || intermediate.levelUps === 0) {
      return;
    }

    const timeouts: NodeJS.Timeout[] = [];

    for (let i = 0; i < intermediate.levelUps; i++) {
      timeouts.push(
        setTimeout(() => {
          ParticleSystemUtil.playEffect('LevelUp', mainScene, particleEffects.levelUp, batchedRenderer);

          if (i === intermediate.levelUps - 1) {
            useUserStore.setState((state) => ({
              ...state,
              intermediate: {
                ...state.intermediate,
                levelUps: 0
              }
            }));
          }
        }, i * 300)
      );
    }

    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, [intermediate.levelUps, particleEffects?.levelUp]);

  useEffect(() => {
    if (particleEffects["colaProtection"]) {
      if (colaUsed) {
        particleEffects["colaProtection"]!.traverse((obj: Object3D) => {
          if (obj instanceof ParticleEmitter && (obj.system as ParticleSystem).onlyUsedByOther === false) {
            (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(20);
          }
        });
      } else {
        particleEffects["colaProtection"]!.traverse((obj: Object3D) => {
          if (obj instanceof ParticleEmitter && (obj.system as ParticleSystem).onlyUsedByOther === false) {
            (obj.system as ParticleSystem).emissionOverTime = new ConstantValue(0);
          }
        });
      }
    }
  }, [colaUsed, particleEffects["colaProtection"]]);

  return enabled
    ? {
        onClick,
      }
    : {};
};
