import styled from "styled-components";
import coinIcon from "../../../assets/icon-coin.svg";
import Starfield from "../../Starfield";
import { Drawer } from "vaul";
import { MissingStats } from "../../FightDrawer/MissingStats";
import { Matchmaking } from "../../FightDrawer/Matchmaking";
import IconClose from '../../../assets/icon-close-drawer.svg';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useGameStore } from "../../../store";
import { AudioSystemUtil } from "../../GameSceneNew/particles/AudioSystemUtil";

const Buttons = () => {
  const navigate = useNavigate();
  const { setGameMode, setBattleSpoils, setBattleState, setBattleError } = useGameStore();
  const [ showFightAgain, setShowFightAgain ] = useState(false);

  return (
    <div className="flex flex-col space-y-3 pt-4 pb-14 px-5">
      <Drawer.Root dismissible={false} open={showFightAgain} onOpenChange={setShowFightAgain}>
        <Drawer.Trigger asChild>
          <button
            type="button"
            className="rounded-lg bg-[#ffffff14] text-white p-2.5 h-12 w-full text-center font-semibold active:bg-[#a2a2a214]"
            onClick={() => {
              setShowFightAgain(true);
            }}
          >
            Fight again
          </button>
        </Drawer.Trigger>
        <Drawer.Portal>
          <Drawer.Overlay className="fixed inset-0 bg-black/40"/>
          <Drawer.Content
            className="bg-[#1B181D] flex flex-col rounded-t-[10px] h-[56%] mt-24 fixed bottom-0 left-0 right-0"
            style={{
              zIndex: 1000
            }}
          >
            <div className="p-4 bg-[#1B181D] rounded-t-2xl flex-1">
              <div className="flex flex-col h-full justify-between">
                <div className="w-full flex justify-end">
                  <button
                    className="rounded-full"
                    onClick={() => setShowFightAgain(false)}
                  >
                    <img src={IconClose} alt={''}/>
                  </button>
                </div>
                <MissingStats onCloseDrawer={() => setShowFightAgain(false)} />
                <Matchmaking onCloseDrawer={() => setShowFightAgain(false)} />
              </div>
            </div>
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>
      <button
        type="button"
        className="rounded-lg bg-white text-black p-2.5 h-12 w-full text-center active:bg-gray-200"
        onClick={() => {
          setBattleSpoils(null);
          setBattleState(null);
          setBattleError(null);
          setGameMode('fighting');
          navigate('/stage3');
        }}
      >
        Nice! Back to training
      </button>
    </div>
  );
};

const DrawScreen = () => {
  const { battleSpoils } = useGameStore();

  useEffect(() => {
    AudioSystemUtil.play('sfx-draw', false);
  }, []);

  return (
    <Container className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-auto no-scrollbar pointer-events-auto">

      <Starfield amount={100}/>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba766] blur-[60px] mx-auto left-[-100px] right-0 top-[200px] rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#a3366d66] blur-[60px] mx-auto left-24 right-0 top-[400px] rounded-[50%] z-0"></div>

      <Content>
        <Appear $delay={0}>
          <Title>
            <div className="text-4xl font-bold text-white mt-4">
              It's a Draw!
            </div>
          </Title>
        </Appear>
        <div className="text-white text-center flex flex-col items-center mt-4">
          <Appear $delay={300}>
            <div>An even match!</div>
          </Appear>
          <Appear $delay={1600}>
            <div>You got</div>
          </Appear>
        </div>
        <Appear $delay={2200}>
          <CoinIcon>
            <img
              className="w-[63px] h-[63px] mt-[58px]"
              src={coinIcon}
              alt="icon"
              style={{ width: 140 }}
            />
          </CoinIcon>
        </Appear>
        <Appear $delay={2300}>
          <Amount>
            <div className="text-3xl">
              {battleSpoils?.coins}
            </div>
          </Amount>
        </Appear>
        <Appear $delay={3600}>
          <Description $size="small">
            <div className="text-[#E3EAF699] text-center flex flex-col items-center mt-8">
              and also
            </div>
          </Description>
        </Appear>
        <Appear $delay={4200}>
          <div className="text-center flex flex-col items-center">
            <ExpBar>
              <div className="text-white text-center">
                + {battleSpoils?.exp} EXP
              </div>
            </ExpBar>
          </div>
        </Appear>
      </Content>
      <Appear $delay={6000}>
        <Buttons />
      </Appear>
    </Container>
  );
};

const DefeatScreen = () => {
  const { battleSpoils } = useGameStore();

  useEffect(() => {
    AudioSystemUtil.play('sfx-defeat', false);
  }, []);

  return (
    <Container className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-auto no-scrollbar pointer-events-auto">

      <Starfield amount={10}/>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba766] blur-[60px] mx-auto left-[-100px] right-0 top-[200px] rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#a3366d66] blur-[60px] mx-auto left-24 right-0 top-[400px] rounded-[50%] z-0"></div>

      <Content>
        <Appear $delay={0}>
          <Title>
            <div className="text-4xl font-bold text-white mt-4">
              Defeat
            </div>
          </Title>
        </Appear>
        <div className="text-white text-center flex flex-col items-center mt-4">
          <Appear $delay={300}>
            <div>You put up a good fight!</div>
          </Appear>
          <Appear $delay={1600}>
            <div>You got</div>
          </Appear>
        </div>
        <Appear $delay={2200}>
          <CoinIcon>
            <img
              className="w-[63px] h-[63px] mt-[58px]"
              src={coinIcon}
              alt="icon"
              style={{ width: 140 }}
            />
          </CoinIcon>
        </Appear>
        <Appear $delay={2300}>
          <Amount>
            <div className="text-3xl">
              {battleSpoils?.coins}
            </div>
          </Amount>
        </Appear>
        <Appear $delay={3600}>
          <Description $size="small">
            <div className="text-[#E3EAF699] text-center flex flex-col items-center mt-8">
              and also
            </div>
          </Description>
        </Appear>
        <Appear $delay={4200}>
          <div className="text-center flex flex-col items-center">
            <ExpBar>
              <div className="text-white text-center">
                + {battleSpoils?.exp} EXP
              </div>
            </ExpBar>
          </div>
        </Appear>
      </Content>
      <Appear $delay={6000}>
        <Buttons />
      </Appear>
    </Container>
  );
};

const VictoryScreen = () => {
  const { battleSpoils } = useGameStore();

  useEffect(() => {
    AudioSystemUtil.play('sfx-victory', false);
  }, []);

  return (
    <Container className="relative flex flex-col justify-between h-dvh w-full bg-[#080D1B] z-5 overflow-y-auto no-scrollbar pointer-events-auto">

      <Starfield amount={100}/>
      <div className="absolute w-[252px] h-[204px] bg-[#513ba766] blur-[60px] mx-auto left-[-100px] right-0 top-[200px] rounded-[50%] z-0"></div>
      <div className="absolute w-[252px] h-[204px] bg-[#a3366d66] blur-[60px] mx-auto left-24 right-0 top-[400px] rounded-[50%] z-0"></div>

      <Content>
        <Appear $delay={0}>
          <Title>
            <div className="text-4xl font-bold text-white mt-4">
              Victory
            </div>
          </Title>
        </Appear>
        <div className="text-white text-center flex flex-col items-center mt-4">
          <Appear $delay={300}>
            <div>Well played!</div>
          </Appear>
          <Appear $delay={1600}>
            <div>You got</div>
          </Appear>
        </div>
        <Appear $delay={2200}>
          <CoinIcon>
            <img
              className="w-[63px] h-[63px] mt-[58px]"
              src={coinIcon}
              alt="icon"
              style={{ width: 140 }}
            />
          </CoinIcon>
        </Appear>
        <Appear $delay={2300}>
          <Amount>
            <div className="text-3xl">
              {battleSpoils?.coins}
            </div>
          </Amount>
        </Appear>
        <Appear $delay={3600}>
          <Description $size="small">
            <div className="text-[#E3EAF699] text-center flex flex-col items-center mt-8">
              and also
            </div>
          </Description>
        </Appear>
        <Appear $delay={4200}>
          <div className="text-center flex flex-col items-center">
            <ExpBar>
              <div className="text-white text-center">
                + {battleSpoils?.exp} EXP
              </div>
            </ExpBar>
          </div>
        </Appear>
      </Content>
      <Appear $delay={6000}>
        <Buttons />
      </Appear>
    </Container>
  );
};

export const FightResult = () => {
  const { battleSpoils, battleState } = useGameStore();

  if (!battleSpoils || !battleState?.user1 || !battleState?.user2) {
    return null;
  }

  if (battleState?.user1.hp === 0 && battleState?.user2.hp! > 0) {
    return <DefeatScreen />;
  } else if (battleState?.user2.hp === 0 && battleState?.user1.hp! > 0) {
    return <VictoryScreen />;
  } else if (battleState?.user1.hp === 0 && battleState?.user2.hp! === 0) {
    return <DrawScreen />;
  }

  return null;
};

const Appear = styled.div<{
  $delay?: number;
}>`
  display: inline-block;
  margin: 0 auto;
  width: inherit;
  animation: fightResultAnimationFlow 0.3s ease-out alternate both 1;
  animation-delay: ${({ $delay }) => $delay ?? 0}ms;
`;

const Content = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  position: relative;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-top: 5%;
  white-space: nowrap;
`;

const Description = styled.div<{
  $size?: 'small';
}>`
  font-size: ${({ $size }) => $size === 'small' ? '0.8rem' : '1rem'};
  font-weight: 600;
  color: ${({ $size }) => $size === 'small' ? '#222033' : '#ffffff'};
  text-align: center;
`;

const CoinIcon = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 140px;
  height: 140px;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 80%;
    margin: 0;
    padding: 0;
  }
`;

const Amount = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
`;

const ExpBar = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  margin-top: 10%;
  border-radius: 12px;
  background-color: #ffffff11;
  padding: 8px;
`;

const Container = styled.div`
  @keyframes fightResultAnimationFlow {
    0% {
      transform: scale(0.5);
      opacity: 0.0;
    }

    70% {
      transform: scale(1.1);
      opacity: 1.0;
    }

    100% {
      transform: scale(1.0);
      opacity: 1.0;
    }
  }

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #090d1b;
  color: #ffffff;
  z-index: 999;
`;
