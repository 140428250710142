import { Howler, Howl } from 'howler';

Howler.autoUnlock = true;

const createAudioTrack = (
  name: string,
  preloadLongFiles: boolean = false
) => {
  const track = new Howl({
    src: [`/assets/sounds/${name}`],
    autoplay: false,
    preload: preloadLongFiles ? 'metadata' : true,
    html5: preloadLongFiles,
    loop: true,
    pool: 1,
  });

  track.once('loaderror', () => {
    track.unload();
  });

  return track;
};

export const audioTracks = {
  'ambient-battle': createAudioTrack('ambient-battle.mp3', true),
  'ambient-birds-chirp': createAudioTrack('ambient-birds-chirp.ogg', true),
  'ambient-theme': createAudioTrack('ambient-theme.ogg', true),
  'sfx-victory': createAudioTrack('sfx-victory.aac'),
  'sfx-defeat': createAudioTrack('sfx-defeat.aac'),
  'sfx-draw': createAudioTrack('sfx-draw.aac'),
  'sfx-evade': createAudioTrack('sfx-evade.aac'),
  'sfx-heating': createAudioTrack('sfx-heating.mp3'),
  'sfx-hit-1': createAudioTrack('sfx-hit-1.aac'),
  'sfx-hit-2': createAudioTrack('sfx-hit-2.aac'),
  'sfx-hit-3': createAudioTrack('sfx-hit-3.aac'),
  'sfx-hit-4': createAudioTrack('sfx-hit-4.aac'),
  'sfx-hit-dummy': createAudioTrack('sfx-hit-dummy.aac'),
  'sfx-level-up': createAudioTrack('sfx-level-up.aac'),
  'sfx-owl-happy-1': createAudioTrack('sfx-owl-happy-1.ogg'),
  'sfx-owl-happy-2': createAudioTrack('sfx-owl-happy-2.ogg'),
  'sfx-owl-happy-3': createAudioTrack('sfx-owl-happy-3.ogg'),
  'sfx-owl-happy-4': createAudioTrack('sfx-owl-happy-4.ogg'),
};

const latestStoppedTracks: Array<[ string, boolean ]> = [];

export const AudioSystemUtil = {
  play: (
    trackName: keyof typeof audioTracks,
    loop: boolean = false,
    volume: number = 1.0,
    rate: number = 1.0
  ) => {
    const track = audioTracks[trackName];

    track.once('playerror', () => {
      track.once('unlock', () => {
        track.play();
      });
    });

    track.loop(loop);
    track.volume(volume);
    track.rate(rate);

    if (loop) {
      track.fade(track.volume(), 1.0, 1000);
    } else {
      track.volume(volume);
    }

    if (
      !loop ||
      (loop && !track.playing())
    ) {
      track.play();
    }
  },
  stop: (
    trackName: keyof typeof audioTracks
  ) => {
    const track = audioTracks[trackName];

    track.fade(track.volume(), 0.0, 1000);
  },
  stopAll: async (): Promise<void> => {
    const pendingFades: Promise<void>[] = [
      new Promise(resolve => setTimeout(resolve, 1000))
    ];

    for (const track of Object.values(audioTracks)) {
      if (track.volume() > 0.0) {
        pendingFades.push(new Promise(resolve => {
          track.once('fade', () => resolve());
        }));

        const trackName = Object.entries(audioTracks).find(
          ([_, value]) => {
            return value === track;
          }
        )?.[0] as keyof typeof audioTracks;

        if (trackName) {
          latestStoppedTracks.push([trackName, track.loop()]);
        }

        track.fade(track.volume(), 0.0, 1000);
      }
    }

    if (!pendingFades.length) {
      return Promise.resolve();
    }

    // @ts-ignore
    return Promise.all(pendingFades);
  },
  resumeLatest: () => {
    for (const [trackName, trackLooped] of latestStoppedTracks) {
      AudioSystemUtil.play(trackName as keyof typeof audioTracks, trackLooped);
    }

    latestStoppedTracks.length = 0;
  },
};
