import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://ef446871b15c0ff17c478e8aeb052634@o4508364509478912.ingest.de.sentry.io/4508364512624720",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  sampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV_DEV === 'true' ? 'dev' : 'prod',
});
